import './CashbackExtension.scss';

import { Grid, Button } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { CmsFile } from '../../interfaces/cmsFile';
import { ContentElements } from '../../interfaces/ContentElements';
import Helper from '../../services/helper';
import StateManager from '../../services/manager/StateManager';
import Box from './../../components/Box/Box';
import CmsManager from './../../services/manager/CmsManager';

interface Props {
  history: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
  isFirefox: boolean;
  isChrome: boolean;
  buttonLabel: string;
  buttonLink: string;
  titelbild?: CmsFile;
  screenShot?: CmsFile;
}

export default class CashbackExtension extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      isFirefox: false,
      isChrome: false,
      buttonLabel: '',
      buttonLink: '',
    };
  }

  detectBrowser() {
    this.setState({
      isChrome: !!/(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      ),
      isFirefox: !!/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent),
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned('cashback-radar');

    const titelbild = content.titelbilderFiles[0] as CmsFile;
    const screenShot = content.screenshotsFiles[0] as CmsFile;

    this.detectBrowser();

    let buttonLabel = '';
    let buttonLink = '';

    if (this.state.isChrome) {
      buttonLabel = 'BonusRadar installieren';
      buttonLink =
        'https://chrome.google.com/webstore/detail/mlp-cashback-toolbar/djgpcibjopchenlpgafpcdifplicfagc?hl=de';
    }

    if (this.state.isFirefox) {
      buttonLabel = 'BonusRadar installieren';
      buttonLink =
        'https://addons.mozilla.org/de/firefox/addon/mlp-cashback-bonusradar/';
    }

    this.setState({
      content,
      loading: false,
      buttonLabel,
      buttonLink,
      titelbild,
      screenShot,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>BonusRadar - MLP Cashback</title>
        </Helmet>
        <div className="BonusRadar">
          {this.state.loading && <Loading />}
          {!this.state.loading && (
            <Fragment>
              <ImageSlider
                images={[
                  {
                    src: this.state.titelbild?.url,
                    contentBox: {
                      headline: (this.state.content?.pageTitle as string) || '',
                      content: this.state.content?.pageDescription as string,
                      button: {
                        label: this.state.buttonLabel,
                        link: this.state.buttonLink,
                      },
                    },
                  },
                ]}
              />

              <InnerLayout>
                <Box
                  style={{ paddingBottom: '10px' }}
                  className="noVerticalPaddingOnMobile"
                >
                  <Headline type="h1" styling="thin">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.content?.pageTitle as string,
                      }}
                    ></span>
                  </Headline>

                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <div
                        className="page-description"
                        dangerouslySetInnerHTML={{
                          __html: this.state.content?.pageDescription as string,
                        }}
                      />

                      {(this.state.isChrome || this.state.isFirefox) && (
                        <a
                          href={this.state.buttonLink}
                          target="_blank noopener norel"
                        >
                          <Button color="primary" variant="contained">
                            {this.state.buttonLabel}
                          </Button>
                        </a>
                      )}

                      {!this.state.isChrome && !this.state.isFirefox && (
                        <div className="warning">
                          <h3>
                            {this.state.content?.browsernotsupportedTitle}
                          </h3>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.content
                                ?.browsernotsupported as string,
                            }}
                          ></p>
                        </div>
                      )}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <img
                        className="explanation"
                        src={this.state.screenShot?.url}
                        alt=""
                      />
                    </Grid>
                  </Grid>

                  <Headline
                    type="h3"
                    styling="thin"
                    style={{ marginBottom: 0 }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.content
                          ?.explanationheadlineTitle as string,
                      }}
                    ></span>
                  </Headline>
                </Box>
              </InnerLayout>

              <Box color="ultraLightBackground" style={{ paddingTop: 0 }}>
                <InnerLayout style={{ marginTop: 0 }}>
                  <Box
                    color="transparent"
                    className="noHorizontalPaddingOnMobile noVerticalPaddingOnMobile"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Headline type="h3" styling="thin">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.content
                                ?.method1Title as string,
                            }}
                          ></span>
                        </Headline>

                        <p
                          className="info-text"
                          dangerouslySetInnerHTML={{
                            __html: this.state.content?.method1 as string,
                          }}
                          onClick={(e: any) =>
                            Helper.clickHandler(e, this.props.history)
                          }
                        ></p>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Headline type="h3" styling="thin">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: this.state.content
                                ?.method2Title as string,
                            }}
                          ></span>
                        </Headline>

                        <p
                          className="info-text"
                          dangerouslySetInnerHTML={{
                            __html: this.state.content?.method2 as string,
                          }}
                          onClick={(e: any) =>
                            Helper.clickHandler(e, this.props.history)
                          }
                        ></p>
                      </Grid>
                    </Grid>
                    <div className="activation">
                      <Headline type="h3" styling="thin">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              ?.activationTitle as string,
                          }}
                        ></span>
                      </Headline>

                      <p
                        className="info-text"
                        dangerouslySetInnerHTML={{
                          __html: this.state.content?.activation as string,
                        }}
                        onClick={(e: any) =>
                          Helper.clickHandler(e, this.props.history)
                        }
                      ></p>
                    </div>
                  </Box>
                </InnerLayout>
              </Box>
            </Fragment>
          )}
        </div>
      </Layout>
    );
  }
}
