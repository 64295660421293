import './Layout.scss';

import {
  createMuiTheme,
  Divider,
  Drawer,
  List,
  ListItem,
  ThemeProvider,
} from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { footerItems, navigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import Navigation from '../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Head from './Head';
import ContactSidebar from '../ContactSidebar/ContactSidebar';

interface Props {
  title?: string;
}

interface State {
  isDrawerOpen: boolean;
  isContactSidebarOpen: boolean;
  loading: boolean;
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#E89E40',
      main: '#E89E40',
      dark: '#E89E40',
      contrastText: '#fff',
    },
    secondary: {
      main: '#688B9E',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
});

export class Layout extends React.Component<Props, State> {
  public navigation = navigationItems;
  public footer = footerItems;

  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      isContactSidebarOpen: false,
      loading: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  toggleContactSidebar = () => {
    this.setState({
      isContactSidebarOpen: !this.state.isContactSidebarOpen,
    });
  };

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  handleClickOutside = (event: any) => {
    const drawerElement = document.querySelector('.sidebarDrawer');
    if (!drawerElement?.contains(event.target)) {
      this.setState({ isContactSidebarOpen: false });
    }
  };

  render() {
    if (!this.state) return;

    const footer = this.footer;

    if (!Auth.isLoggedIn()) {
      footer.splice(4, 1);
    }

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {Auth.isLoggedIn() && <meta name="fkn" content={Auth.getFKN()} />}
        </Head>

        <div className="layout" onClick={this.handleClickOutside}>
          <Drawer
            onBackdropClick={this.closeDrawer}
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <List>
              {this.navigation.map((item, index) => (
                <ListItem key={'nav-item-' + index} button>
                  <NavLink
                    style={{ width: '100%', margin: '6px 0' }}
                    key={'nav-' + index}
                    activeClassName="active"
                    to={item.link}
                  >
                    {item.title}
                  </NavLink>
                </ListItem>
              ))}

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: '100%', margin: '6px 0' }}
                    activeClassName="active"
                    to={'/mein-konto'}
                  >
                    Mein Konto
                  </NavLink>
                </ListItem>
              )}

              {!Auth.isLoggedIn() && (
                <ListItem button>
                  <a
                    style={{ width: '100%', margin: '6px 0' }}
                    href="https://financepilot-pe.mlp.de/banking-private/entry?linie=mlp-financepilot&prt=report"
                  >
                    Login
                  </a>
                </ListItem>
              )}

              <Divider />

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: '100%', margin: '6px 0 0' }}
                    activeClassName="active"
                    to={'/abmelden'}
                  >
                    Logout
                  </NavLink>
                </ListItem>
              )}
            </List>
          </Drawer>

          <Navigation
            items={this.navigation}
            toggleDrawer={this.toggleDrawer}
          />

          <ContactSidebar
            isOpen={this.state.isContactSidebarOpen}
            toggleSidebarDrawer={this.toggleContactSidebar}
          />

          <div className="content-wrapper">{this.props.children}</div>

          <Footer items={this.footer} />
        </div>
      </ThemeProvider>
    );
  }
}
