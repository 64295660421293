import './Login.scss';

import { faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../components/Box/Box';
import Headline from '../components/Headline/Headline';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import Auth from '../services/auth';
import UserManager from '../services/manager/UserManager';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
    display: 'block',
    marginBottom: 10,
  },
  adornment: {
    height: 'auto',
    width: 'auto',
  },
  icon: {
    padding: 10,
    width: '40px!important',
    height: '40px!important',
  },
}));

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();

  const handleResendRegisterMail = () => {
    const MySwal = withReactContent(Swal);

    UserManager.resendVerification(username).then(() => {
      MySwal.fire(
        '',
        'Wir haben Ihnen eine neue Bestätigungs-E-Mail gesendet. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.',
        'success'
      );
    });
  };

  const login = () => {
    Auth.login(username, password).then(response => {
      if (response === 'invalidCredentials') {
        const LoginSwal = withReactContent(Swal);

        LoginSwal.fire({
          title: 'Login fehlgeschlagen',
          text:
            'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.',
          icon: 'error',
          confirmButtonText: 'OK',
          footer:
            !!username &&
            username.indexOf('@') !== -1 &&
            username.indexOf('.') !== -1 ? (
              <button
                onClick={() => handleResendRegisterMail()}
                className="resendButton"
              >
                Bestätigungs-E-Mail erneut senden
              </button>
            ) : (
              false
            ),
        });
      } else if (response === 'notVerified') {
        Swal.fire(
          '',
          'Sie haben sich noch nicht verifiziert. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.',
          'error'
        );
      }
    });
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login - MLP Cashback</title>
      </Helmet>

      <Box className="noHorizontalPaddingOnMobile">
        <InnerLayout className="Login">
          <Box>
            <Headline type="h1" styling="thin">
              Willkommen in der MLP Vorteilswelt
            </Headline>
          </Box>
        </InnerLayout>
      </Box>

      <Box color="blue">
        <InnerLayout className="Login">
          <Box color="transparent" className="noHorizontalPaddingOnMobile">
            <Box className="login-box">
              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                wrap="nowrap"
                style={{ marginBottom: 30 }}
              >
                <Grid item className="login-icon">
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faUser}
                    style={{ color: '#688B9E' }}
                  />
                </Grid>

                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth={true}
                    value={username}
                    className={`${classes.fullWidth} loginField`}
                    onChange={event => setUsername(event.target.value)}
                    onKeyDown={event => {
                      if (event.keyCode === 13) login();
                    }}
                    label="Benutzername"
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                alignItems="flex-end"
                wrap="nowrap"
                style={{ marginBottom: 30 }}
              >
                <Grid item className="login-icon">
                  <FontAwesomeIcon
                    className={classes.icon}
                    icon={faKey}
                    style={{ color: '#688B9E' }}
                  />
                </Grid>

                <Grid item style={{ flexGrow: 1 }}>
                  <TextField
                    fullWidth={true}
                    value={password}
                    className={`${classes.fullWidth} loginField`}
                    onChange={event => setPassword(event.target.value)}
                    onKeyDown={event => {
                      if (event.keyCode === 13) login();
                    }}
                    label="Passwort"
                    type="password"
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  <Typography align="right" className="loginButtons">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={login}
                      className="submitLogin"
                      style={{ float: 'right' }}
                    >
                      Login Vorteilswelt
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </InnerLayout>
      </Box>
    </Layout>
  );
}

export default Login;
