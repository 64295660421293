import './CreateComplaint.scss';

import DateFnsUtils from '@date-io/date-fns';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import validator from 'validator';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Submenu from '../../components/Submenu/Submenu';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import { subNavigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';

interface Props {}

interface State {
  shops: Shop[];
  loading: boolean;
  user?: User;
  sent: boolean;
  privacy: boolean;
  transactionDate: Date;
  name: string;
  mail: string;
  used: string;
  shopId: string;
  cashBackRate: string;
  cashBackRateType: string;
  productName: string;
  price: number;
  orderNumber: string;
  billNumber: string;
  customerNumber: string;
  comment: string;
  errors: any;

  confirmedCashback?: number;
  pendingCashback?: number;
  travelCashbackConfirmed?: number;
  ticketCashbackConfirmed?: number;
  openComplaints?: number;
  cashoutProfile?: any;
  cleanCashbackRates?: any;
}

export default class CreateComplaint extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      shops: [],
      loading: true,
      sent: false,
      privacy: false,
      transactionDate: new Date(),
      name: '',
      mail: '',
      used: '',
      shopId: '',
      cashBackRate: '',
      cashBackRateType: '',
      productName: '',
      price: 0,
      orderNumber: '',
      billNumber: '',
      customerNumber: '',
      comment: '',
      errors: {},
      confirmedCashback: 0,
      pendingCashback: 0,
      travelCashbackConfirmed: 0,
      ticketCashbackConfirmed: 0,
      openComplaints: 0,
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFieldValue = this.getFieldValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const [
      shops,
      user,
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
      numberOfComplaints,
      travelTransactions,
      ticketTransactions,
    ] = await Promise.all([
      CashbackManager.findLastClickoutShops(),
      UserManager.me(),
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getTicketTransactions(),
    ]);

    const sumsTravel = CashbackManager.getCashbackAmount(
      travelTransactions.items
    );

    const sumsTicket = CashbackManager.getCashbackAmount(
      ticketTransactions.items
    );

    const cleanShops = _.uniqBy(shops, 'id');

    this.setState({
      shops: cleanShops,
      name: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      mail: '',
      used: '',
      shopId: '',
      user,
      loading: false,
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
      travelCashbackConfirmed: sumsTravel.sumCollected,
      ticketCashbackConfirmed: sumsTicket.sumCollected,
      openComplaints: numberOfComplaints,
    });
  }

  isValid() {
    let errorsCount = 0;
    const errors: any = {};

    const FIELDS_TO_VALIDATE: Array<keyof State> = [
      'privacy',
      'mail',
      'used',
      'orderNumber',
      'name',
      'cashBackRate',
      'shopId',
      'transactionDate',
    ];

    const validateField = (field: keyof State) => {
      if (!this.state[field]) {
        errors[field] = 'Dies ist ein Pflichtfeld';
        errorsCount++;
      } else {
        errors[field] = '';
      }
    };

    FIELDS_TO_VALIDATE.forEach(validateField);

    if (!validator.isEmail(this.state.mail)) {
      errors.mail = 'Ungültige E-Mail-Adresse';
      errorsCount++;
    } else {
      errors.mail = '';
    }

    if (!this.state.hasOwnProperty('product')) {
      errors.product = 'Dies ist ein Pflichtfeld';
      errorsCount++;
    } else {
      errors.product = '';
    }

    this.setState({ errors });
    return errorsCount === 0;
  }

  async handleSubmit() {
    if (!this.isValid()) return;

    this.setState({
      loading: true,
    });

    const [cashBackRate, cashBackRateType] = this.state.cashBackRate.split('|');

    await CashbackManager.createComplaint({
      shopId: this.state.shopId,
      fkn: Auth.getFKN(),
      name: this.state.name,
      mail: this.state.mail,
      used: this.state.used,
      productName: this.state.productName,
      orderNumber: this.state.orderNumber,
      customerNumber: this.state.customerNumber,
      price: this.state.price,
      billNumber: this.state.billNumber,
      transactionDate: Helper.dateToString(this.state.transactionDate),
      comment: this.state.comment,
      cashBackRate,
      cashBackRateType: cashBackRateType === '%' ? 'perc' : 'eur',
    });

    Alert.success(
      'Vielen Dank für Ihre Anfrage!',
      'Ihre Nachbuchungsanfrage wird so schnell wie möglich bearbeitet.'
    );

    this.setState({
      sent: true,
      loading: false,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState(
        {
          [field]:
            field === 'transactionDate'
              ? e
              : field === 'privacy'
              ? e.target.checked
              : e.target.value,
          ...(field === 'shopId' ? { cashBackRate: '' } : {}),
        } as any,
        () => {
          if (field === 'shopId') {
            const tempCashbackRates = this.state.shops.find(
              shop => shop.id === this.state.shopId
            )?.cashbackRates;

            if (tempCashbackRates) {
              const cleanCashbackRates = _.sortBy(
                _.uniqBy(tempCashbackRates, 'amount'),
                ['type', 'amount']
              );

              this.setState({
                cleanCashbackRates,
              });
            }

            this.forceUpdate();
          }
        }
      );
    };
  }

  getFieldValue(field: string): string {
    const state: any = this.state as any;
    return state[field];
  }

  render() {
    if (this.state.sent) return <Redirect to="/nachbuchungsanfragen" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Neue Nachbuchungsanfrage - MLP Cashback</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout>
                <Loading />
              </InnerLayout>
            </div>
          )}

          {!this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout className="createComplaint">
                <Box style={{ paddingBottom: '60px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Gesamtguthaben"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt offen</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.openCash || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt verfügbar</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.bankBalance || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt ausgezahlt</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.paidOutCash +
                                      this.state.cashoutProfile
                                        .externalPaidOutCash || 0
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Cashback bestätigt"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Online Cashback</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.confirmedCashback || 0
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Reiserückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.travelCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Nachbuchungsanfragen"
                        link="/nachbuchungsanfragen"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>offene Anfragen</p>
                            </div>

                            <div className="dataCell">
                              <p>{this.state.openComplaints || 0}</p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>
                  </Grid>
                </Box>

                <Box className="noVerticalPaddingOnMobile">
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12}>
                      <Headline type="h3" styling="thin">
                        Neue Nachbuchung&shy;sanfrage
                      </Headline>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <FormControl variant="filled">
                                <InputLabel>Shop *</InputLabel>
                                <Select
                                  value={this.state.shopId}
                                  onChange={this.handleChange('shopId')}
                                >
                                  {[...this.state.shops]
                                    .filter(ele => ele.cashbackRates?.length)
                                    .map((s, key) => (
                                      <MenuItem key={key} value={s.id}>
                                        {s.name}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                              {this.state.errors.shopId && (
                                <small className="formError">
                                  {this.state.errors.shopId}
                                </small>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd.MM.yyyy"
                                  margin="normal"
                                  label="Einkaufsdatum *"
                                  value={this.state.transactionDate}
                                  onChange={this.handleChange(
                                    'transactionDate'
                                  )}
                                  style={{ margin: 0 }}
                                  inputVariant={'filled'}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  name="transactionDate"
                                  invalidDateMessage="Falsches Format. Bitte Datum wie folgt eingeben: TT.MM.JJJJ"
                                />
                              </MuiPickersUtilsProvider>
                              {this.state.errors.transactionDate && (
                                <small className="formError">
                                  {this.state.errors.transactionDate}
                                </small>
                              )}
                            </Grid>

                            {this.state.shopId && (
                              <Grid item xs={12} lg={6}>
                                <FormControl variant="filled">
                                  <InputLabel>Erwartetes Cashback *</InputLabel>
                                  <Select
                                    value={this.state.cashBackRate}
                                    onChange={this.handleChange('cashBackRate')}
                                    name="cashBackRate"
                                  >
                                    {this.state.cleanCashbackRates &&
                                      this.state.cleanCashbackRates.map(
                                        (cashbackRate: any, key: number) => (
                                          <MenuItem
                                            key={key}
                                            value={
                                              cashbackRate.amount +
                                              '|' +
                                              cashbackRate.type
                                            }
                                          >
                                            {Helper.formatNumber(
                                              cashbackRate.amount,
                                              2
                                            ) +
                                              ' ' +
                                              cashbackRate.type}
                                          </MenuItem>
                                        )
                                      )}
                                  </Select>
                                </FormControl>
                                {this.state.errors.cashBackRate && (
                                  <small className="formError">
                                    {this.state.errors.cashBackRate}
                                  </small>
                                )}
                              </Grid>
                            )}
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="Bestellnummer *"
                                variant="outlined"
                                value={this.getFieldValue('orderNumber')}
                                onChange={this.handleChange('orderNumber')}
                                name="orderNumber"
                              />
                              {this.state.errors['orderNumber'] && (
                                <small className="formError">
                                  {this.state.errors['orderNumber']}
                                </small>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="Bestellter Artikel *"
                                variant="outlined"
                                value={this.getFieldValue('product')}
                                onChange={this.handleChange('product')}
                                name="product"
                              />
                              {this.state.errors['product'] && (
                                <small className="formError">
                                  {this.state.errors['product']}
                                </small>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="Rechnungsnummer"
                                variant="outlined"
                                value={this.getFieldValue('billNumber')}
                                onChange={this.handleChange('billNumber')}
                                name="billNumber"
                              />
                              {this.state.errors['billNumber'] && (
                                <small className="formError">
                                  {this.state.errors['billNumber']}
                                </small>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <Fragment>
                                <TextField
                                  label="Warenkorbwert *"
                                  variant="outlined"
                                  autoComplete={'family-name'}
                                  value={this.getFieldValue('price')}
                                  onChange={this.handleChange('price')}
                                  name="price"
                                />
                                {this.state.errors['price'] && (
                                  <small className="formError">
                                    {this.state.errors['price']}
                                  </small>
                                )}
                              </Fragment>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="Name des Bestellers *"
                                variant="outlined"
                                value={this.getFieldValue('name')}
                                onChange={this.handleChange('name')}
                                name="name"
                              />
                              {this.state.errors['name'] && (
                                <small className="formError">
                                  {this.state.errors['name']}
                                </small>
                              )}
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="Bei deiner Bestellung benutzte E-Mail-Adresse *"
                                variant="outlined"
                                value={this.getFieldValue('used')}
                                onChange={this.handleChange('used')}
                                name="used"
                              />
                              {this.state.errors['used'] && (
                                <small className="formError">
                                  {this.state.errors['used']}
                                </small>
                              )}
                            </Grid>

                            <Grid item xs={12} lg={6}>
                              <TextField
                                label="E-Mail-Adresse, um Sie zu kontaktieren *"
                                variant="outlined"
                                value={this.getFieldValue('mail')}
                                onChange={this.handleChange('mail')}
                                name="mail"
                              />
                              {this.state.errors['mail'] && (
                                <small className="formError">
                                  {this.state.errors['mail']}
                                </small>
                              )}
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="Kommentar"
                                name="comment"
                              />
                            </Grid>
                          </Grid>

                          <small className="validation-explanation">
                            Mit * markierte Felder sind Pflichtfelder
                          </small>

                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.privacy}
                                  onChange={this.handleChange('privacy')}
                                  value="true"
                                  color="primary"
                                />
                              }
                              label={
                                <p>
                                  Ich habe die{' '}
                                  <Link to="/datenschutz" target="_blank">
                                    Datenschutz&shy;informationen
                                  </Link>{' '}
                                  gelesen und akzeptiere diese. *
                                </p>
                              }
                            />
                          </FormControl>
                          {this.state.errors.privacy && (
                            <small className="formError">
                              {this.state.errors.privacy}
                            </small>
                          )}

                          <Grid
                            item
                            xs={12}
                            lg={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-end',
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.handleSubmit}
                            >
                              Absenden
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>

                      <br />
                    </Grid>
                  </Grid>
                </Box>
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
