import './Accordion.scss';

import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Helper from '../../services/helper';
import Headline from '../Headline/Headline';

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiExpansionPanelDetails);

export default function Accordion({ items }: any) {
  const history = useHistory();

  const [expanded, setExpanded] = React.useState<string | false>('x');

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    newExpanded: boolean
  ) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {items.map((item: any, index: number) => (
        <ExpansionPanel
          square
          expanded={expanded === 'panel' + index}
          onChange={handleChange('panel' + index)}
          key={'panel-' + index}
        >
          <ExpansionPanelSummary
            aria-controls={'panel' + index + 'd-content'}
            id={'panel' + index + 'd-header'}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={{ marginRight: 10, color: '#000000' }}
              className="add"
            />

            <FontAwesomeIcon
              icon={faMinus}
              style={{ marginRight: 10, color: '#688B9E' }}
              className="remove"
            />

            <Headline type="h3" styling="thin">
              {item.title}
            </Headline>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails
            style={{
              paddingLeft: 30,
            }}
            dangerouslySetInnerHTML={{ __html: item.content as string }}
            onClick={(e: any) => Helper.clickHandler(e, history)}
          ></ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}
