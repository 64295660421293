import './ImageSlider.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/swiper.scss';

import React from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

interface Props {
  style?: any;
  images: {
    src: any;
    contentBox?: {
      aboveHeadline?: string;
      headline?: string;
      subline?: string;
      button?: {
        label: string;
        link?: string;
        onClick?: () => void;
      };
      content?: string;
    };
    showServiceTile?: boolean;
    tel?: string;
  }[];
}

function ImageSlider(props: Props) {
  const { images } = props;

  if (images.length > 1) SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  return (
    <div style={{ ...props.style }}>
      <Swiper
        className="ImageSlider"
        onSlideChange={() => {}}
        onSwiper={swiper => {}}
        pagination={{ clickable: true }}
        loop={images.length > 1}
        allowTouchMove={images.length > 1}
        autoplay={{ delay: 5000 }}
        key={images.length}
        autoHeight={false}
      >
        {images.map((image, index) => (
          <SwiperSlide className="slide" key={index}>
            <img src={image.src} className="responsiveImage" alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default ImageSlider;
