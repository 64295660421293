import './Dashboard.scss';

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Button, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import Submenu from '../../components/Submenu/Submenu';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import { subNavigationItems } from '../../routes';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import Cookies from 'universal-cookie';

interface Props {
  history: any;
}

interface State {
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  travelCashbackConfirmed: number;
  ticketCashbackConfirmed: number;
  favoriteShops: Shop[];
  loading: boolean;
  user?: User;
  content?: ContentElements;
}

const cookies = new Cookies();

export default class Dashboard extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      travelCashbackConfirmed: 0,
      ticketCashbackConfirmed: 0,
      favoriteShops: [],
      loading: true,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.getUser();

    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
      openComplaints,
      travelTransactions,
      ticketTransactions,
      content,
      favoriteShops,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getTicketTransactions(),
      CmsManager.getPageContentsCleaned('dashboard'),
      CashbackManager.findFavoriteShops(),
    ]);

    const sumsTravel = CashbackManager.getCashbackAmount(
      travelTransactions.items
    );
    const sumsTicket = CashbackManager.getCashbackAmount(
      ticketTransactions.items
    );

    this.setState({
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
      openComplaints,
      travelCashbackConfirmed: sumsTravel.sumCollected,
      ticketCashbackConfirmed: sumsTicket.sumCollected,
      content,
      favoriteShops: Array.isArray(favoriteShops) ? favoriteShops : [],
      loading: false,
    });
  }

  async getUser() {
    const [user, content = {}] = await Promise.all([
      UserManager.me(),
      CmsManager.getPageContentsCleaned('iban-modal'),
    ]);

    if (user?.userInfo?.sepa_iban) {
      sessionStorage.setItem('showIbanModal', 'false');
    } else if (sessionStorage.getItem('showIbanModal') !== 'false') {
      this.handleIbanModal(content);
    }

    this.setState({
      user,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.favoriteShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        favoriteShops: shops,
      });
    };
  }

  getBrowserName() {
    if (
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      )
    )
      return 'chrome';

    if (/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent))
      return 'firefox';

    return '';
  }

  handleNewsletter() {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: 'question',
      title: 'Newsletteranmeldung',
      html:
        'Ich möchte künftig den MLP Vorteilswelt Newsletter per E-Mail erhalten und über aktuelle Angebote, Schnäppchen, Gutscheine und Aktionen informiert werden. Diese Einwilligung kann jederzeit am Ende jeder E-Mail widerrufen werden.',
      confirmButtonText: 'Anmelden',
      cancelButtonText: 'Schließen',
      showCancelButton: true,
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        const registered = await UserManager.registerNewsletter();

        if (registered) {
          MySwal.fire({
            icon: 'success',
            title: 'Vielen Dank',
            confirmButtonText: 'Schließen',
            timer: 5000,
            timerProgressBar: true,
            focusConfirm: false,
          });
        }

        this.getUser();
      }
    });
  }

  handleIbanModal(content: ContentElements) {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: 'info',
      title: content.pageTitle,
      html: content.pageDescription,
      confirmButtonText: 'IBAN angeben',
      cancelButtonText: 'Jetzt nicht',
      showCancelButton: true,
      focusConfirm: false,
    }).then(async result => {
      sessionStorage.setItem('showIbanModal', 'false');

      if (result.isConfirmed) {
        window.location.href = '/meine-daten/meine-auszahlungseinstellungen';
      }
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dashboard - MLP Vorteilsportal</title>
        </Helmet>
        <div className="Dashboard">
          {this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout>
                <Loading />
              </InnerLayout>
            </div>
          )}

          {!this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout>
                <Box>
                  <Headline type="h1" styling="thin">
                    {this.state.user && (
                      <Fragment>
                        Hallo {this.state.user.userInfo.firstName}{' '}
                        {this.state.user.userInfo.lastName},
                      </Fragment>
                    )}
                    {!this.state.user && <Fragment>Hallo,</Fragment>}
                  </Headline>

                  {this.state.content && this.state.content.pageDescription && (
                    <div className="dashboardIntro">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.pageDescription as string,
                        }}
                      />
                      <p>
                        Ihre Cashback-Kundennummer lautet:{' '}
                        {this.state.user?.userInfo._aditoBasicContractId}
                      </p>
                    </div>
                  )}
                </Box>
                <Box style={{ paddingTop: 0, paddingBottom: '60px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Gesamtguthaben"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt offen</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.openCash || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt verfügbar</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.bankBalance || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt ausgezahlt</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.paidOutCash +
                                      this.state.cashoutProfile
                                        .externalPaidOutCash || 0
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Cashback bestätigt"
                        link="/gesamtuebersicht?status=collected"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Online Cashback</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.confirmedCashback || 0
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Reiserückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.travelCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div>
                          {/* <div className="dataRow">
                            <div className="dataCell">
                              <p>Ticketrückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.ticketCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Nachbuchungsanfragen"
                        link="/nachbuchungsanfragen"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>offene Anfragen</p>
                            </div>

                            <div className="dataCell">
                              <p>{this.state.openComplaints || 0}</p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>
                  </Grid>
                </Box>
              </InnerLayout>

              {this.state.favoriteShops && this.state.favoriteShops.length > 0 && (
                <Box color="blue">
                  <InnerLayout>
                    <Box
                      color="transparent"
                      style={{ paddingTop: 0 }}
                      className="noHorizontalPaddingOnMobile"
                    >
                      <Grid container spacing={4}>
                        <Grid item xs={12}>
                          <Headline type="h2" style={{ textAlign: 'center' }}>
                            Cashback-Favoriten
                          </Headline>

                          <Grid container spacing={2}>
                            {this.state.favoriteShops.map((shop: Shop, key) => (
                              <Grid item xs={12} sm={6} md={3} key={key}>
                                <ShopTile
                                  image={shop.logo}
                                  buttonLabel="Zum Shop"
                                  morePath={`/shop/${shop.id}`}
                                  buttonLink={shop.link}
                                  isFavorite={shop.isFavorite}
                                  onFavoriteChange={this.handleFavoriteChange(
                                    shop
                                  )}
                                  history={this.props.history}
                                  shopId={shop.id}
                                  iban={this.state.user?.userInfo?.sepa_iban}
                                >
                                  {shop.maximumCashback && (
                                    <Fragment>
                                      Bis zu{' '}
                                      <span>
                                        {Helper.formatNumber(
                                          shop.maximumCashback,
                                          2
                                        )}{' '}
                                        {shop.maximumCashbackType}
                                      </span>{' '}
                                      bei {shop.name}
                                    </Fragment>
                                  )}
                                </ShopTile>
                              </Grid>
                            ))}
                          </Grid>

                          <div
                            style={{
                              textAlign: 'center',
                              padding: '1em',
                              paddingTop: '3em',
                            }}
                          >
                            <Link
                              to={{
                                pathname: '/cashback/shops',
                                state: {
                                  filterState: {
                                    direction: 'desc',
                                    filters: ['favorites'],
                                    favorites: true,
                                    orderBy: 'popularity',
                                    page: 1,
                                  },
                                },
                              }}
                            >
                              <Button color="primary" variant="contained">
                                Alle Cashback-Favoriten anzeigen
                              </Button>
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  </InnerLayout>
                </Box>
              )}
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
