import './Login.scss';

import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import Box from '../components/Box/Box';
import Headline from '../components/Headline/Headline';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import { Loading } from '../components/Loading';
import { ContentElements } from '../interfaces/ContentElements';
import { DownloadLink } from '../interfaces/downloadLink';
import CmsManager from '../services/manager/CmsManager';

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
  link?: DownloadLink;
}

export default class SsoLogin extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }
  async componentDidMount() {
    const content = await CmsManager.getPageContentsCleaned('login');

    const link = content.linkLinks[0] as DownloadLink;

    this.setState({
      content,
      loading: false,
      link,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Login - MLP Cashback</title>
        </Helmet>

        {this.state.loading && <Loading />}

        {!this.state.loading && (
          <div>
            <Box>
              <InnerLayout className="Login">
                {this.state.content &&
                  this.state.content.pageTitle &&
                  this.state.content.pageDescription &&
                  this.state.content.linkLinks &&
                  this.state.link && (
                    <Box style={{ marginTop: 40 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Headline type="h1">
                            {this.state.content.pageTitle}
                          </Headline>

                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.content
                                .pageDescription as string,
                            }}
                          />

                          <a
                            href={this.state.link.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button variant="outlined" color="primary">
                              zum MLP Portal
                            </Button>
                          </a>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </InnerLayout>
            </Box>
          </div>
        )}
      </Layout>
    );
  }
}
