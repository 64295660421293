import './ShopTile.scss';

import { faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

import Auth from '../../services/auth';
import Helper from '../../services/helper';
import { AuthButton } from '../AuthButton';

interface Props {
  children?: any;
  image?: string;
  buttonLabel?: string;
  morePath?: string;
  buttonLink?: string;
  name?: string;
  displayName?: string;
  buttonStyle?: CSSProperties;
  history: any;
  important?: string;
  shopId: number | string;
  type?: string;
  iban?: string;

  isFavorite?: boolean;
  onFavoriteChange?: (isFavorite: boolean) => void | Promise<void>;
}

export default function ShopTile(props: Props) {
  return (
    <div className="ShopTile">
      <div className="ShopTile-image-wrapper">
        {props.image && props.morePath && (
          <Link to={props.morePath} className="imageLink">
            <img className="ShopTile-image" src={props.image} alt="" />
          </Link>
        )}

        {props.image && !props.morePath && (
          <img className="ShopTile-image" src={props.image} alt="" />
        )}

        {!props.image && props.morePath && (
          <Link to={props.morePath}>
            <h3>{props.displayName || props.name}</h3>
          </Link>
        )}

        {!props.image && !props.morePath && (
          <h3>{props.displayName || props.name}</h3>
        )}
      </div>
      <div className="ShopTile-main">
        <div className="ShopTile-content">{props.children}</div>

        {props.morePath && props.type !== 'product' && (
          <Link className="moreLink" to={props.morePath}>
            mehr details
          </Link>
        )}

        {props.morePath && props.type === 'product' && (
          <Link className="moreLink" to={props.morePath}>
            ergebnisübersicht
          </Link>
        )}

        {props.buttonLabel && props.buttonLink && (
          <AuthButton
            color="primary"
            variant="outlined"
            size="medium"
            href={props.iban && props.buttonLink}
            target="_blank"
            style={props.buttonStyle}
            onClick={(e: any) =>
              Helper.handleIbanPopUp(
                {
                  iban: props.iban,
                  buttonLink: props.buttonLink || '',
                  shopId: String(props.shopId),
                  shopName: String(props.name),
                  history: props.history,
                },
                e
              )
            }
          >
            {props.buttonLabel}
          </AuthButton>
        )}

        {props.isFavorite !== undefined && Auth.isLoggedIn() && (
          <div className="ShopTile-star">
            {props.isFavorite && (
              <FontAwesomeIcon
                icon={faStarSolid}
                onClick={() =>
                  props.onFavoriteChange
                    ? props.onFavoriteChange(!props.isFavorite)
                    : {}
                }
              />
            )}

            {props.isFavorite !== true && (
              <FontAwesomeIcon
                icon={faStar}
                onClick={() =>
                  props.onFavoriteChange
                    ? props.onFavoriteChange(!props.isFavorite)
                    : {}
                }
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
