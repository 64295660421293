import './IntroSection.scss';

import { faPhoneVolume } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { CmsFile } from '../../interfaces/cmsFile';
import { DownloadLink } from '../../interfaces/downloadLink';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import Headline from '../Headline/Headline';

type ContentElementType = string | DownloadLink[] | CmsFile[] | undefined;
interface ServiceHours {
  days: ContentElementType;
  from: ContentElementType;
  to: ContentElementType;
}
interface Props {
  headline: ContentElementType;
  text?: ContentElementType;
  contactInformation?: {
    phoneNumber?: ContentElementType;
    serviceHours?: ServiceHours[];
  };
  fkn?: string;
}

export default function IntroSection(props: Props) {
  const { headline, text, contactInformation } = props;
  const history = useHistory();

  let phoneNumberLink = undefined;

  if (contactInformation) {
    const { phoneNumber } = contactInformation;

    phoneNumberLink =
      typeof phoneNumber === 'string'
        ? `tel:+49${phoneNumber
            .split('')
            .filter(e => Number(e) || e === '0')
            .filter((e, i) => !(e === '0' && !i))
            .join('')}`
        : '';
  }

  return (
    <div className="IntroSection">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Headline type="h1" styling="thin">
            {headline}
          </Headline>
        </Grid>

        <Grid item xs={12} md={8}>
          {props.text && (
            <p
              className="intro-text"
              dangerouslySetInnerHTML={{ __html: text as string }}
              onClick={(e: any) => Helper.clickHandler(e, history)}
            />
          )}

          {Auth.isLoggedIn() && props.fkn && (
            <p>Ihre Cashback-Kundennummer lautet: {props.fkn}</p>
          )}
        </Grid>

        <Grid item xs={12} md={4}>
          {contactInformation && (
            <div className="contact-information">
              {phoneNumberLink && (
                <div className="phone">
                  <a href={phoneNumberLink}>
                    <FontAwesomeIcon icon={faPhoneVolume} />
                    <span>{contactInformation.phoneNumber}</span>
                  </a>
                </div>
              )}

              <div className="service-hours">
                {contactInformation.serviceHours?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>{item.days}</div>
                      <div>
                        {item.from} bis {item.to} Uhr
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
