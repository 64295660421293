import './Cashback.scss';

import { Grid, Input, MenuItem, Select, Slider } from '@material-ui/core';
import queryString from 'query-string';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { NavLink } from 'react-router-dom';

import Box from '../../components/Box/Box';
import CashbackFilterBox from '../../components/CashbackFilterBox/CashbackFilterBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Pagination from '../../components/Pagination/Pagination';
import ShopTile from '../../components/ShopTile/ShopTile';
import { CashbackCategory } from '../../interfaces/cashbackCategory';
import Product from '../../interfaces/product';
import SearchShop from '../../interfaces/searchShop';
import Shop from '../../interfaces/shop';
import SpecialInterface from '../../interfaces/special';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';

interface Props {
  match: any;
  location: any;
  history: any;
}

interface State {
  mode: string;
  query: string;
  shops: Shop[];
  products: Product[];
  productShops: SearchShop[];
  loading: boolean;
  page: number;
  numberOfPages: number;
  filters: string[];
  order: string;
  shopsFilter: string;
  priceRange: [number, number];
  priceRangeFilter: [number, number];
  categories: CashbackCategory[];
  categoryFilter: number;
  specials: SpecialInterface[];
  filterState: any;
  user?: User;
}

export default class CashbackProducts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: 'products',
      query: '',
      shops: [],
      products: [],
      productShops: [],
      loading: true,
      page: 1,
      numberOfPages: 0,
      filters: [],
      shopsFilter: '-1',
      order: 'priority',
      priceRange: [0, 0],
      priceRangeFilter: [0, 0],
      categories: [],
      categoryFilter: 0,
      specials: [],
      filterState: {},
    };

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleChangeShopFilter = this.handleChangeShopFilter.bind(this);
    this.handleChangePriceRangeFilter = this.handleChangePriceRangeFilter.bind(
      this
    );
    this.handleChangePriceRangeFilterFromInput = this.handleChangePriceRangeFilterFromInput.bind(
      this
    );
    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeCategoryFilter = this.handleChangeCategoryFilter.bind(
      this
    );
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.handlePriceRangeSelected = this.handlePriceRangeSelected.bind(this);
  }

  handleChangePriceRangeFilter(event: any, value: any) {
    this.setState({
      priceRangeFilter: value,
    });
  }

  handleChangePriceRangeFilterFromInput(event: any, position: number) {
    const { value } = event.target;
    let priceRangeFilter: [number, number];
    if (position === 0) {
      priceRangeFilter = [value, this.state.priceRangeFilter[1]];
    } else {
      priceRangeFilter = [this.state.priceRangeFilter[0], value];
    }

    this.setState({
      priceRangeFilter,
    });
  }

  async handlePriceRangeSelected() {
    let minRange =
      this.state.priceRangeFilter[0] > this.state.priceRange[0]
        ? this.state.priceRangeFilter[0]
        : this.state.priceRange[0];
    let maxRange =
      this.state.priceRangeFilter[1] < this.state.priceRange[1]
        ? this.state.priceRangeFilter[1]
        : this.state.priceRange[1];
    if (minRange > this.state.priceRange[1]) {
      minRange = this.state.priceRange[1] - 1;
    }
    if (maxRange < this.state.priceRange[0]) {
      maxRange = this.state.priceRange[0];
    }
    if (minRange > maxRange) {
      minRange = this.state.priceRange[0];
      maxRange = this.state.priceRange[1];
    }
    await this.setState({
      priceRangeFilter: [minRange, maxRange],
    });

    this.load();
  }

  handleChangeShopFilter(event: any) {
    this.setState(
      {
        shopsFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeCategoryFilter(event: any) {
    this.setState(
      {
        categoryFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeOrder(event: any) {
    this.setState(
      {
        order: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.shops;
      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        shops,
      });
    };
  }

  handleRemoveFilter() {
    this.setState(
      {
        mode: 'products',
        query: '',
        shops: [],
        products: [],
        productShops: [],
        loading: true,
        page: 1,
        numberOfPages: 0,
        filters: [],
        shopsFilter: '-1',
        order: 'priority',
        priceRange: [0, 0],
        priceRangeFilter: [0, 0],
        categories: [],
        categoryFilter: 0,
        specials: [],
        filterState: {},
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeMode(mode: string) {
    return () => {
      this.setState(
        {
          mode,
          page: 1,
        },
        () => {
          this.load();
        }
      );
    };
  }

  handleChangePage(page: number) {
    this.setState(
      {
        page,
        loading: true,
      },
      () => {
        this.load();
      }
    );

    window.scrollTo({
      top: 0,
    });
  }

  async componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }

    const categories = await CashbackManager.findCategories();
    const specials = await CashbackManager.findSpecials();

    this.setState({
      categories: categories.items,
      specials,
    });

    const params = queryString.parse(this.props.location.search) as any;

    const newState: any = {};

    if (params.q) {
      newState.query = params.q;
    }

    // Get old filter state
    let oldState = StateManager.getState('pap');

    if (this.props.location?.state?.filterState) {
      oldState = this.props.location.state.filterState;
      this.props.location.state.filterState = null;
    }

    if (oldState) {
      if (oldState.query) newState.query = oldState.query;
      if (oldState.page) newState.page = oldState.page;
      if (oldState.order) newState.order = oldState.order;
      if (oldState.filters) newState.filters = oldState.filters;
      if (oldState.categoryFilter)
        newState.categoryFilter = oldState.categoryFilter;
      if (oldState.mode) newState.mode = oldState.mode;
      if (oldState.shopsFilter) newState.shopsFilter = oldState.shopsFilter;
      if (oldState.priceRangeFilter)
        newState.priceRangeFilter = oldState.priceRangeFilter;
    }

    this.setState(newState);

    this.load();
  }

  async load() {
    await this.setState({ loading: true });

    let filterState = {
      query: this.state.query,
      page: this.state.page,
      order: this.state.order,
      filters: this.state.filters,
      categoryFilter: this.state.categoryFilter,
      mode: this.state.mode,
      shopsFilter: this.state.shopsFilter,
      priceRangeFilter: this.state.priceRangeFilter,
    };
    if (this.props.location?.state?.filterState) {
      filterState = this.props.location.state.filterState;
      this.props.location.state.filterState = null;
    }

    await this.setState({
      filterState,
    });

    StateManager.setState('pap', filterState);

    if (this.state.query.trim().length < 3)
      return this.setState({ loading: false });

    const params = {
      page: this.state.page,
      query: this.state.query,
      filters: {},
    } as any;

    if (this.state.shopsFilter !== '-1')
      params.filters['shops[]'] = this.state.shopsFilter;

    if (this.state.order !== '') params.filters.order = this.state.order;

    let priceRangeFilterIsSet = false;

    if (
      this.state.priceRange[0] !== this.state.priceRangeFilter[0] ||
      this.state.priceRange[1] !== this.state.priceRangeFilter[1]
    ) {
      params.filters.priceRange = {
        min: this.state.priceRangeFilter[0],
        max: this.state.priceRangeFilter[1],
      };

      priceRangeFilterIsSet = true;
    }

    const products = await CashbackManager.findProducts(params);

    await this.setState({
      order: params.filters.order,
      products: products.products,
      productShops: products.shops,
      shops: [],
      loading: false,
      page: products.currentPage,
      numberOfPages: Math.ceil(products.numberOfResults / 50),
      priceRange: [products.lowestPrice, products.highestPrice],
      mode: 'products',
      priceRangeFilter: priceRangeFilterIsSet
        ? this.state.priceRangeFilter
        : [products.lowestPrice, products.highestPrice],
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleSearch() {
    this.setState(
      {
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  renderSortingSelect() {
    const sortings = [
      { label: 'Relevanz', value: 'priority' },
      { label: 'Preis aufsteigend', value: 'asc' },
      { label: 'Preis absteigend', value: 'desc' },
    ];

    return (
      <Select
        className="rounded-select"
        value={this.state.order}
        onChange={this.handleChangeOrder}
      >
        {sortings.map((order, key) => (
          <MenuItem value={order.value} key={key}>
            {order.label}
          </MenuItem>
        ))}
      </Select>
    );
  }

  renderPriceRangeFilter() {
    const priceRangeInputConfig = [
      { prefix: 'von', position: 0 },
      { prefix: 'bis', position: 1 },
    ];

    return (
      <div>
        <div className="priceRangeFilter">
          <Slider
            value={
              this.state.priceRangeFilter
                ? this.state.priceRangeFilter
                : this.state.priceRange
            }
            min={this.state.priceRange[0]}
            max={this.state.priceRange[1]}
            getAriaValueText={(value: number) => Helper.formatPrice(value || 0)}
            onChange={this.handleChangePriceRangeFilter}
            onChangeCommitted={() => this.load()}
            valueLabelDisplay="on"
          />
        </div>

        <div className="priceRangeInputs">
          {priceRangeInputConfig.map(ele => (
            <div className="priceRangeInput">
              <span>{ele.prefix}</span>
              <Input
                className="price-range"
                disableUnderline
                inputProps={{
                  min: this.state.priceRange[0],
                  max: this.state.priceRange[1],
                }}
                type="number"
                value={
                  this.state.priceRangeFilter
                    ? this.state.priceRangeFilter[ele.position]
                    : this.state.priceRange[ele.position]
                }
                onChange={(event: any) =>
                  this.handleChangePriceRangeFilterFromInput(
                    event,
                    ele.position
                  )
                }
                onBlur={this.handlePriceRangeSelected}
              />
              <span className="currency">EUR</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderShopFilter() {
    return (
      <Select
        className="rounded-select"
        value={this.state.shopsFilter}
        onChange={this.handleChangeShopFilter}
      >
        <MenuItem value={'-1'}>Shop</MenuItem>

        {this.state.productShops.map((shop: SearchShop, key) => (
          <MenuItem key={key} value={shop.id}>
            {shop.name}
          </MenuItem>
        ))}
      </Select>
    );
  }

  renderCategoryFilter() {
    return (
      <Select
        className="rounded-select"
        value={this.state.categoryFilter}
        onChange={this.handleChangeCategoryFilter}
      >
        <MenuItem value={0}>Kategorie</MenuItem>

        {this.state.categories.map((category: CashbackCategory, key) => (
          <MenuItem key={key} value={category.id}>
            {category.name}
          </MenuItem>
        ))}
      </Select>
    );
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Cashback-Produkte - MLP Cashback</title>
        </Helmet>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && (
          <div className="CashbackList">
            <InnerLayout>
              <Box>
                <div className="shopTypeSwitchWrapper">
                  <NavLink
                    to={'/cashback/shops'}
                    className="shopTypeSwitch"
                    activeClassName="active"
                  >
                    Shop suchen
                  </NavLink>

                  <NavLink
                    to={'/cashback/produkte'}
                    className="shopTypeSwitch"
                    activeClassName="active"
                  >
                    Produkt suchen
                  </NavLink>
                </div>
                <CashbackFilterBox
                  placeholder="Suchbegriff eingeben"
                  buttonLabel="Finden"
                  onSubmit={this.handleSearch}
                  onResetFilter={this.handleRemoveFilter}
                  onChange={this.handleChangeQuery}
                  query={this.state.query}
                  // categoryFilter={this.renderCategoryFilter({})}
                  // quickFilter={this.renderQuickFilter({})}
                  sortingSelect={
                    this.state.products && this.state.products.length > 0
                      ? this.renderSortingSelect()
                      : false
                  }
                  shopFilter={
                    this.state.products && this.state.products.length > 0
                      ? this.renderShopFilter()
                      : false
                  }
                  priceRangeFilter={
                    this.state.products && this.state.products.length > 0
                      ? this.renderPriceRangeFilter()
                      : false
                  }
                  filterRowGrid={{ left: 6, right: 6 }}
                  showReset={
                    this.state.filters.length > 0 || this.state.query !== ''
                  }
                ></CashbackFilterBox>
              </Box>
            </InnerLayout>

            <Box color="blue">
              <InnerLayout>
                {this.state.products &&
                  this.state.products.length === 0 &&
                  this.state.filterState.query.length > 3 && (
                    <Box color="blue">
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        Leider konnten für diesen Suchbegriff keine Ergebnisse
                        gefunden werden.
                      </p>
                    </Box>
                  )}

                {this.state.products && this.state.products.length > 0 && (
                  <Box
                    color="transparent"
                    className="noHorizontalPaddingOnMobile"
                  >
                    <Grid container spacing={2} className="products-list">
                      <Grid item xs={12}>
                        <Headline type="h2">
                          Produktergebnisse für {this.state.query}
                        </Headline>
                      </Grid>

                      {this.state.products.map((product: Product, key) => {
                        return (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <ShopTile
                              image={product.imageUrl}
                              buttonLabel="Zum Shop"
                              morePath={`/produkt/${product.id}`}
                              buttonLink={product.link}
                              buttonStyle={{
                                marginTop: 15,
                                borderWidth: '2px',
                              }}
                              history={this.props.history}
                              shopId={product.shop.id}
                              iban={this.state.user?.userInfo?.sepa_iban}
                              type="product"
                            >
                              {product.name}

                              <div
                                style={{
                                  marginTop: 20,
                                }}
                              >
                                ab{' '}
                                <span className="color-primary">
                                  {Helper.formatPrice(
                                    product.price / 100,
                                    product.currency || 'EUR'
                                  )}
                                </span>
                                {product.duplicatesCount > 0 && (
                                  <Fragment>
                                    <span>in</span>
                                    <span className="color-primary">
                                      {` ${product.duplicatesCount} Shops`}
                                    </span>
                                  </Fragment>
                                )}
                              </div>
                            </ShopTile>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Pagination
                      currentPage={this.state.page}
                      numberOfPages={this.state.numberOfPages}
                      onPageChange={this.handleChangePage}
                    />
                  </Box>
                )}
              </InnerLayout>
            </Box>
          </div>
        )}
      </Layout>
    );
  }
}
