import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import Helper from '../../services/helper';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  history: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class Disclaimer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned(
      'haftungsausschluss'
    );

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Disclaimer">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Haftungsausschluss - MLP Cashback</title>
          </Helmet>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                {this.state.content && this.state.content.pageTitle && (
                  <h1>{this.state.content.pageTitle}</h1>
                )}

                <Grid container spacing={2}>
                  {this.state.content && this.state.content.pageDescription && (
                    <Grid item xs={12} lg={8}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.state.content.pageDescription as string,
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
