import './Submenu.scss';

import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Submenu(props: { items: any[] }) {
  return (
    <div className="submenu">
      <div className="submenuWrapper">
        <nav className="subNavigation">
          <ul>
            {props.items.map((item, index) => {
              if (item.link.indexOf('http') === -1) {
                return (
                  <li key={'sub-nav-' + index}>
                    <NavLink activeClassName="active" to={item.link}>
                      {item.title}
                    </NavLink>
                  </li>
                );
              }

              if (item.link.indexOf('http') === 0) {
                return (
                  <li key={'sub-nav-' + index}>
                    <a
                      target="_blank"
                      href={item.link}
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  </li>
                );
              }
              return false;
            })}
            <li>
              <NavLink activeClassName="active" to="/abmelden">
                Logout{' '}
                <FontAwesomeIcon icon={faSignOut} style={{ marginLeft: 5 }} />
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
