import './Settings.scss';

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Submenu from '../../components/Submenu/Submenu';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import { ContentElements } from '../../interfaces/ContentElements';
import { User } from '../../interfaces/user';
import { subNavigationItems } from '../../routes';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';

interface State {
  loading: boolean;
  user?: User;
  contractId: string;
  plusCardNumber: string;
  plusCardNumberError?: string;
  content?: ContentElements;
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  travelCashbackConfirmed: number;
  ticketCashbackConfirmed: number;
}

interface Props {}

export default class Settings extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      travelCashbackConfirmed: 0,
      ticketCashbackConfirmed: 0,
      confirmedCashback: 0,
      pendingCashback: 0,
      loading: true,
      contractId: '',
      plusCardNumber: '',
    };

    this.handleChangeContractId = this.handleChangeContractId.bind(this);
    this.handleSubmitContractId = this.handleSubmitContractId.bind(this);
    this.handleChangeplusCardNumber = this.handleChangeplusCardNumber.bind(
      this
    );
  }

  async componentDidMount() {
    const result = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getTicketTransactions(),
      CmsManager.getPageContentsCleaned('settings'),
      UserManager.me(),
    ]);

    const sumsTravel = CashbackManager.getCashbackAmount(result[3].items);
    const sumsTicket = CashbackManager.getCashbackAmount(result[4].items);

    this.setState({
      loading: false,
      user: result[6],
      confirmedCashback: result[0].confirmed,
      pendingCashback: result[0].open,
      cashoutProfile: result[1],
      openComplaints: result[2],
      content: result[5],
      travelCashbackConfirmed: sumsTravel.sumCollected,
      ticketCashbackConfirmed: sumsTicket.sumCollected,
    });
  }

  handleChangeContractId(e: any) {
    this.setState({
      contractId: e.target.value,
    });
  }

  async handleSubmitContractId() {
    if (this.state.contractId.trim() === '') return;

    this.setState({
      loading: true,
    });

    await UserManager.updateSettings({
      contractId: this.state.contractId.trim(),
    });

    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,
    });
  }

  handleChangeplusCardNumber(e: any) {
    this.setState({
      plusCardNumber: e.target.value,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Meine Daten - MLP Cashback</title>
        </Helmet>

        {this.state.loading && (
          <div>
            <Submenu items={this.subNavigation} />
            <InnerLayout>
              <Loading />
            </InnerLayout>
          </div>
        )}

        {!this.state.loading && this.state.user && (
          <div className="Settings">
            <Submenu items={this.subNavigation} />

            <InnerLayout>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Gesamtguthaben"
                      link="/gesamtuebersicht"
                      icon={faChevronRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>Gesamt offen</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {this.state.cashoutProfile &&
                                Helper.formatPrice(
                                  this.state.cashoutProfile.openCash || 0
                                )}
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>Gesamt verfügbar</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {this.state.cashoutProfile &&
                                Helper.formatPrice(
                                  this.state.cashoutProfile.bankBalance || 0
                                )}
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>Gesamt ausgezahlt</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {this.state.cashoutProfile &&
                                Helper.formatPrice(
                                  this.state.cashoutProfile.paidOutCash +
                                    this.state.cashoutProfile
                                      .externalPaidOutCash || 0
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Cashback bestätigt"
                      link="/gesamtuebersicht?status=collected"
                      icon={faChevronRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>Online Cashback</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {Helper.formatPrice(
                                this.state.confirmedCashback || 0
                              )}
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>Reiserückvergütung</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {Helper.formatPrice(
                                this.state.travelCashbackConfirmed || 0
                              )}
                            </p>
                          </div>
                        </div>
                        {/* <div className="dataRow">
                          <div className="dataCell">
                            <p>Ticketrückvergütung</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {Helper.formatPrice(
                                this.state.ticketCashbackConfirmed || 0
                              )}
                            </p>
                          </div>
                        </div> */}
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Nachbuchungsanfragen"
                      link="/nachbuchungsanfragen"
                      icon={faChevronRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offene Anfragen</p>
                          </div>

                          <div className="dataCell">
                            <p>{this.state.openComplaints || 0}</p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>
                </Grid>
              </Box>

              {this.state.content &&
                this.state.content.pageTitle &&
                this.state.content.pageDescription && (
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={8}>
                        <Headline type="h3" styling="thin">
                          {this.state.content.pageTitle}
                        </Headline>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              .pageDescription as string,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
            </InnerLayout>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box color="blue">
                  <InnerLayout>
                    <Box
                      color="transparent"
                      className="noHorizontalPaddingOnMobile"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                          <Box>
                            <div className="headlineWrapper">
                              <Headline type="h2" size="small">
                                Persönliche Angaben
                              </Headline>

                              <Link
                                to="/meine-daten/persoenliche-angaben"
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                Bearbeiten
                              </Link>
                            </div>

                            <Grid container spacing={2}>
                              {[
                                {
                                  label: 'Vorname',
                                  value: this.state.user.userInfo.firstName,
                                },
                                {
                                  label: 'Nachname',
                                  value: this.state.user.userInfo.lastName,
                                },
                                {
                                  label: 'E-Mail',
                                  value: this.state.user.email,
                                },
                              ].map(({ label, value }, key) => (
                                <Grid item md={6} xs={12} key={key}>
                                  <Grid container>
                                    <Grid item xs={5}>
                                      <span
                                        style={{
                                          color: '#000',
                                        }}
                                      >
                                        {label}:
                                      </span>
                                    </Grid>
                                    <Grid item xs={7}>
                                      {value}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>

                        <Grid item xs={12} style={{ marginBottom: 30 }}>
                          <Box>
                            <div className="headlineWrapper">
                              <Headline
                                type="h2"
                                size="small"
                                style={{ display: 'inline-block' }}
                              >
                                Auszahlungs&shy;ein&shy;stellung&shy;en
                              </Headline>

                              <Link
                                to="/meine-daten/meine-auszahlungseinstellungen"
                                style={{
                                  display: 'inline-block',
                                }}
                              >
                                Bearbeiten
                              </Link>
                            </div>

                            <Grid container spacing={2}>
                              {[
                                {
                                  label: 'IBAN',
                                  value: this.state.user.userInfo.sepa_iban
                                    ? this.state.user.userInfo.sepa_iban?.substr(
                                        this.state.user.userInfo.sepa_iban
                                          .length - 4
                                      )
                                    : '',
                                },
                              ].map(({ label, value }, key) => (
                                <Grid item md={6} xs={12} key={key}>
                                  <Grid container>
                                    <Grid item xs={3} sm={5}>
                                      <span
                                        style={{
                                          color: '#000',
                                        }}
                                      >
                                        {label}:
                                      </span>
                                    </Grid>
                                    <Grid item xs={9} sm={7}>
                                      {value !== ''
                                        ? `******************${value}`
                                        : value}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </InnerLayout>
                </Box>
              </Grid>
            </Grid>
          </div>
        )}
      </Layout>
    );
  }
}
