import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import validator from 'validator';

import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Submenu from '../../components/Submenu/Submenu';
import { User } from '../../interfaces/user';
import { subNavigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';
import { promises } from 'fs';
import CashbackManager from '../../services/manager/CashbackManager';

interface State {
  loading: boolean;
  redirect: boolean;

  user?: User;

  iban: string;

  errors: object;
}

interface Props {}

export default class ChangeCashoutCredentials extends React.Component<
  Props,
  State
> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      iban: '',

      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const user = await UserManager.me();

    const iban = user.userInfo.sepa_iban?.substr(
      user.userInfo.sepa_iban.length - 4
    );

    this.setState({
      loading: false,
      user,
      iban: iban !== '' ? `******************${iban}` : '',
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value,
      } as any);
    };
  }

  isPasswordSecure(password: string) {
    return !!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/);
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ['iban'];

    fields.forEach((field: string) => {
      if (!state[field] || !validator.isIBAN(state[field]))
        errors[field] = 'Die eingegebene IBAN ist ungültig.';
    });

    this.setState({
      errors,
    });

    return JSON.stringify(errors) === '{}';
  }

  async handleSubmit() {
    if (!this.validate()) return;

    this.setState({ loading: true });

    if (this.state.user && this.state.iban) {
      const [updatedIban, updatedAutoCashoutSubscribe] = await Promise.all([
        UserManager.updateIban(this.state.iban),
        CashbackManager.setAutoCashoutSubscribe({ status: true }),
      ]);

      Alert.success('Daten geändert', 'Ihre IBAN wurde erfolgreich geändert.');
    }

    this.setState({
      redirect: true,
      loading: false,
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/meine-daten" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Auszahlungseinstellungen bearbeiten - MLP Cashback</title>
        </Helmet>
        {this.state.loading && (
          <div>
            <Submenu items={this.subNavigation} />
            <InnerLayout>
              <Loading />
            </InnerLayout>
          </div>
        )}

        {!this.state.loading && this.state.user && (
          <div>
            <Submenu items={this.subNavigation} />
            <Box>
              <InnerLayout className="Settings">
                <Box>
                  <Headline type="h3" styling="thin">
                    Meine Auszahlungseinstellungen
                  </Headline>
                </Box>
              </InnerLayout>
            </Box>

            <Box color="blue">
              <InnerLayout>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="IBAN"
                        variant="filled"
                        autoComplete={'iban'}
                        value={this.state.iban}
                        onChange={this.handleChange('iban')}
                        error={errors.iban !== undefined}
                        helperText={errors.iban ? errors.iban : ''}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Speichern
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </InnerLayout>
            </Box>
          </div>
        )}
      </Layout>
    );
  }
}
