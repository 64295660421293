import { Button, PropTypes } from '@material-ui/core';
import * as React from 'react';
import { CSSProperties } from 'react';

import Auth from '../services/auth';

interface Props {
  color?: PropTypes.Color;
  disableFocusRipple?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  href?: string;
  target?: string;
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  children: any;
  style?: CSSProperties;
  onClick?: (e: any) => void;
}

export const AuthButton = (props: Props) => {
  if (Auth.isLoggedIn()) {
    return <Button {...props}>{props.children}</Button>;
  } else {
    return (
      <a href="https://financepilot-pe.mlp.de/banking-private/entry?linie=mlp-financepilot&prt=report">
        <Button color="primary" variant="contained">
          Zum Login
        </Button>
      </a>
    );
  }
};
