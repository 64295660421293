import './Headline.scss';

import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React from 'react';

interface Props {
  type: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  color?: string;
  children: any;
  size?: 'small';
  style?: CSSProperties;
  id?: string;
  styling?: 'thin';
  classNames?: string;
}

export default class Headline extends React.Component<Props, {}> {
  render() {
    const CustomTag = `${this.props.type}` as keyof JSX.IntrinsicElements;
    const classNames = `${this.props.color ? this.props.color : ''} ${
      this.props.size ? this.props.size : ''
    } ${this.props.styling ? this.props.styling : ''} ${
      this.props.classNames ? this.props.classNames : ''
    }`;

    return (
      <CustomTag
        className={`${classNames} Headline`}
        style={{ ...this.props.style }}
        id={this.props.id}
      >
        {this.props.children}
      </CustomTag>
    );
  }
}
