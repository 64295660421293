import './Footer.scss';

import { Container } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Footer(props: { items: any[] }) {
  const year = new Date().getFullYear();

  return (
    <div className="footer">
      <div>
        <Container>
          <div className="footer-wrapper">
            <div className="footerCopyright">© {year} MEHRWERK</div>
            <nav className="footerText">
              {props.items.map((item, index) => {
                if (item.link.indexOf('http') === -1) {
                  return (
                    <NavLink
                      key={'footer-nav-' + index}
                      activeClassName="active"
                      to={item.link}
                    >
                      {item.title}
                    </NavLink>
                  );
                }

                if (item.link.indexOf('http') === 0) {
                  return (
                    <a
                      target="_blank"
                      key={'footer-nav-' + index}
                      href={item.link}
                      rel="noopener noreferrer"
                    >
                      {item.title}
                    </a>
                  );
                }
                return false;
              })}
            </nav>
          </div>
        </Container>
      </div>
    </div>
  );
}
