import {
  Button,
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Link } from 'react-router-dom';

import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { User } from '../../interfaces/user';
import { Alert } from '../../services/alert';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';
import Submenu from '../../components/Submenu/Submenu';
import { subNavigationItems } from '../../routes';
import validator from 'validator';

interface State {
  loading: boolean;
  redirect: boolean;

  user?: User;

  email: string;
  firstname: string;
  lastname: string;
  privacy: boolean;

  errors: object;
}

interface Props {}

export default class ChangeCredentials extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      email: '',
      firstname: '',
      lastname: '',
      privacy: false,

      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,
      firstname: user.userInfo.firstName,
      lastname: user.userInfo.lastName,
      email: user.email,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      field !== 'privacy'
        ? this.setState({
            [field]: e.target.value,
          } as any)
        : this.setState({
            [field]: e.target.checked,
          } as any);
    };
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ['firstname', 'lastname', 'privacy'];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === 'string' && state[field].trim() === '')
      )
        errors[field] = 'Dieses Feld muss ausgefüllt werden';
    });

    if (!state.email) {
      errors.email = 'Bitte geben Sie Ihre E-Mail-Adresse ein.';
    } else if (!validator.isEmail(state.email)) {
      errors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
    }

    this.setState({
      errors,
    });

    return JSON.stringify(errors) === '{}';
  }

  async handleSubmit() {
    if (!this.validate()) return;

    this.setState({ loading: true });

    if (
      this.state.user &&
      this.state.email &&
      this.state.firstname &&
      this.state.lastname &&
      this.state.privacy
    ) {
      await UserManager.updateEmail(this.state.email);
      await UserManager.updateFirstname(this.state.firstname);
      await UserManager.updateLastname(this.state.lastname);

      Alert.success(
        'Daten geändert',
        'Ihre Daten wurden erfolgreich geändert. Sie erhalten nun eine Bestätigungsmail. Bitte bestätigen Sie Ihre Änderung mit Klick auf den Link in der E-Mail und melden sich erneut im MLP Kundenportal an.'
      );
    }

    this.setState({
      redirect: true,
      loading: false,
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/" />;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Persönliche Daten bearbeiten - MLP Cashback</title>
        </Helmet>
        {this.state.loading && (
          <div>
            <Submenu items={this.subNavigation} />
            <InnerLayout>
              <Loading />
            </InnerLayout>
          </div>
        )}

        {!this.state.loading && this.state.user && (
          <div>
            <Submenu items={this.subNavigation} />
            <Box>
              <InnerLayout className="Settings">
                <Box>
                  <Headline type="h1">Meine persönlichen Daten</Headline>
                </Box>
              </InnerLayout>
            </Box>

            <Box color="blue">
              <InnerLayout>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Vorname"
                        variant="filled"
                        autoComplete={'firstname'}
                        value={this.state.firstname}
                        onChange={this.handleChange('firstname')}
                        error={errors.firstname !== undefined}
                        helperText={errors.firstname ? errors.firstname : ''}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        label="Nachname"
                        variant="filled"
                        autoComplete={'lastname'}
                        value={this.state.lastname}
                        onChange={this.handleChange('lastname')}
                        error={errors.lastname !== undefined}
                        helperText={errors.lastname ? errors.lastname : ''}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        label="E-Mail-Adresse"
                        variant="filled"
                        autoComplete={'email'}
                        value={this.state.email}
                        onChange={this.handleChange('email')}
                        error={errors.email !== undefined}
                        helperText={errors.email ? errors.email : ''}
                      />
                    </Grid>

                    <Grid item xs={6} />

                    <Grid item xs={12}>
                      <FormControl error={errors.privacy !== undefined}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={this.state.privacy}
                              onChange={this.handleChange('privacy')}
                              color="primary"
                              style={
                                errors.privacy
                                  ? {
                                      borderWidth: 1,
                                      borderColor: 'red',
                                      borderStyle: 'solid',
                                    }
                                  : {}
                              }
                            />
                          }
                          label={
                            <p>
                              Ich habe die{' '}
                              <Link to="/datenschutz" target="_blank">
                                Datenschutzinformationen
                              </Link>{' '}
                              und{' '}
                              <Link to="/agb" target="_blank">
                                AGB
                              </Link>{' '}
                              gelesen und akzeptiere diese.
                            </p>
                          }
                        />
                        {errors.privacy && (
                          <FormHelperText>{errors.privacy}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Speichern
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </InnerLayout>
            </Box>
          </div>
        )}
      </Layout>
    );
  }
}
