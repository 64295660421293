import { CashbackCategory } from '../../interfaces/cashbackCategory';
import { CashbackComplaint } from '../../interfaces/cashbackComplaint';
import { CashbackShoppingTransaction } from '../../interfaces/cashbackShoppingTransaction';
import { CashbackTicketTransaction } from '../../interfaces/cashbackTicketTransaction';
import { CashbackTravelTransaction } from '../../interfaces/cashbackTravelTransaction';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import { CreateCashbackComplaint } from '../../interfaces/createCashbackComplaint';
import { List } from '../../interfaces/list';
import Product from '../../interfaces/product';
import ProductOffer from '../../interfaces/productOffer';
import ProductSearchResult from '../../interfaces/productSearchResult';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { API } from '../API';
import { Cashout } from './../../interfaces/cashout';

export interface TransactionStatusSums {
  sumOpen: number;
  sumCancelled: number;
  sumCollected: number;
}

type TransactionType =
  | CashbackShoppingTransaction
  | CashbackTicketTransaction
  | CashbackTravelTransaction;

type CashbackState = 'open' | 'confirmed' | 'cancelled' | 'canceled';
export default class CashbackManager {
  static async createReclamation(id: string, data: any) {
    const result = await API.post(
      `cashback/complaints/transaction/${id}`,
      data,
      false
    );
    return JSON.parse(result);
  }

  static async setAutoCashoutSubscribe(data: any): Promise<any> {
    return await API.post(
      `cashback/cashouts/autoCashOutSubscribe/${data.status.toString()}`,
      {},
      false
    );
  }

  static async createComplaint(data: any) {
    return await API.post(`cashback/complaints`, data, false);
  }

  static async findShops(params: any = {}): Promise<List<Shop>> {
    return await API.get(`cashback/shops?${API.encodeURL(params)}`);
  }

  static async findShopByCategoryId(
    id: any,
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop>> {
    return await API.get(
      `cashback/shops?category=${id}&page=${page}&itemsPerPage=${itemsPerPage}&orderBy=popularity&direction=desc`
    );
  }

  static async findProducts(params: any = {}): Promise<ProductSearchResult> {
    return await API.get(`pap/products/search?${API.encodeURL(params)}`);
  }

  static async findShop(id: string): Promise<Shop> {
    return await API.get(`cashback/shops/${id}`);
  }

  static async findFavoriteShops(): Promise<any> {
    return await API.get(`cashback/shops/favorites`);
  }

  static async findCategories(): Promise<List<CashbackCategory>> {
    return await API.get(`cashback/categories`);
  }

  static async findNewestShops(
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop>> {
    return await API.get(
      `cashback/shops?page=${page}&itemsPerPage=${itemsPerPage}&orderBy=createdAt&direction=desc`
    );
  }

  static async findMostLikedShops(
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop>> {
    return await API.get(
      `cashback/shops?page=${page}&itemsPerPage=${itemsPerPage}&orderBy=popularity&direction=desc`
    );
  }

  static async findProduct(id: string): Promise<Product> {
    return await API.get(`pap/products/${id}/detail`);
  }

  static async findProductOffers(
    unifiedProductId: string
  ): Promise<ProductOffer[]> {
    return await API.get(
      `pap/products/offers?unifiedProductId=${unifiedProductId}&query=`
    );
  }

  static async removeShopFavorite(id: string) {
    return await API.delete(`cashback/shops/${id}/favorite`);
  }

  static async setShopFavorite(id: string) {
    return await API.post(`cashback/shops/${id}/favorite`, {});
  }

  static async findSpecials(): Promise<Special[]> {
    const specials = await API.get(`cashback/specials`);

    return specials?.filter((special: any) => {
      if (special.status !== 1) return false;
      try {
        if (new Date(special.end) < new Date()) return false;
        if (new Date(special.start) > new Date()) return false;
      } catch (e) {
        console.error(e);
      }
      return true;
    });
  }

  static async findLastClickoutShops(): Promise<Array<Shop>> {
    const clickouts: List<{
      createdAt: string;
      fkn: string;
      id: string;
      shop: Shop;
      subId: any;
    }> = await API.get('cashback/clickouts?itemsPerPage=100');

    return clickouts.items
      ? clickouts.items.map(clickout => clickout.shop)
      : [];
  }

  static async findCashouts() {
    return await API.get(`cashback/cashouts`);
  }

  static async getCashouts(
    startDate = '',
    endDate = ''
  ): Promise<List<Cashout>> {
    return await API.get(
      `cashback/cashouts?${startDate ? 'startDate=' + startDate + '&' : ''}${
        endDate ? 'endDate=' + endDate + '&' : ''
      }`
    );
  }

  static async getCashoutProfile(): Promise<CashoutProfile> {
    return await API.get(`cashback/cashouts/profile`);
  }

  static async complaint(data: CreateCashbackComplaint) {
    return await API.post(`cashback/complaints`, data);
  }

  static async findComplaints(): Promise<List<CashbackComplaint>> {
    return await API.get(
      'cashback/complaints?page=1&itemsPerPage=1000&orderBy=createdAt&direction=desc'
    );
  }

  static async getNumberOfComplaints(): Promise<number> {
    const complaints = await API.get(
      'cashback/complaints?page=1&itemsPerPage=1000'
    );

    if (complaints.items) {
      return complaints.items.filter(
        (complaint: CashbackComplaint) => complaint.status === 'open'
      ).length;
    }

    return 0;
  }

  static getCashbackAmount(transactions: any): TransactionStatusSums {
    let sumOpen = 0;
    let sumCollected = 0;
    let sumCancelled = 0;

    if (transactions) {
      transactions.forEach((transaction: any) => {
        if (
          transaction.status === 'open' &&
          transaction.status === 'confirmed'
        ) {
          sumOpen += transaction.cashOutAmount;
        }
        if (transaction.status === 'collected') {
          sumCollected += transaction.cashOutAmount;
        }
        if (
          transaction.status === 'canceled' ||
          transaction.status === 'cancelled'
        ) {
          sumCancelled += transaction.cashOutAmount;
        }
      });
    }

    return { sumCollected, sumCancelled, sumOpen };
  }

  static async getCashbackAmountSums(
    cashbackStates: CashbackState[]
  ): Promise<{ [key in CashbackState]?: number }> {
    const openStatuses = ['open', 'confirmed'];
    const confirmedStatuses = ['collected'];

    const transactions = await Promise.all([
      this.getOnlineTransactions(),
      this.getTicketTransactions(),
      this.getTravelTransactions(),
    ]);

    const result: { [key in CashbackState]?: number } = {};

    for (const cashbackState of cashbackStates) {
      const cashbackStateSum = transactions.reduce((sum, transactions) => {
        if (transactions.items) {
          transactions.items.forEach((transaction: TransactionType) => {
            if (cashbackState === 'open') {
              if (openStatuses.includes(transaction.status)) {
                sum += transaction.cashOutAmount;
              }
            } else if (cashbackState === 'confirmed') {
              if (confirmedStatuses.includes(transaction.status)) {
                sum += transaction.cashOutAmount;
              }
            }
          });
        }
        return sum;
      }, 0);

      result[cashbackState] = cashbackStateSum;
    }

    return result;
  }

  static async getOnlineTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackShoppingTransaction>> {
    return await API.get(
      `cashback/transactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }

  static async getTravelTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackTravelTransaction>> {
    return await API.get(
      `cashback/traveltransactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }

  static async getTicketTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackTicketTransaction>> {
    return await API.get(
      `cashback/tickettransactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }
}
