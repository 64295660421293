import { User } from '../../interfaces/user';
import UserLegitimation from '../../interfaces/userLegitimation';
import UserRegistration from '../../interfaces/userRegistration';
import { API } from '../API';

export default class UserManager {
  static async me(): Promise<User> {
    const me = await API.get(`users/me`);

    return me;
  }

  static async updateSettings(settings: any): Promise<User> {
    return await API.patch(`users/settings`, settings);
  }

  static async updateEmail(email: string): Promise<User> {
    return await API.patch(`users/me`, {
      email,
      username: email,
    });
  }

  static async updateFirstname(firstname: string): Promise<User> {
    return await API.patch(`users/me`, {
      firstName: firstname,
    });
  }

  static async updateLastname(lastname: string): Promise<User> {
    return await API.patch(`users/me`, {
      lastName: lastname,
    });
  }

  static async updateIban(iban: string): Promise<User> {
    return await API.patch(`users/me`, {
      sepa_iban: iban,
    });
  }

  static async registerNewsletter(): Promise<User> {
    return await API.patch(`users/me`, {
      newsletter: true,
    });
  }

  static async updatePassword(password: string): Promise<User> {
    return await API.patch(`users/me`, {
      password,
    });
  }

  static async register(userId: string, data: UserRegistration): Promise<User> {
    return await API.patch(`users/${userId}`, data);
  }

  static async legitimate(data: UserLegitimation): Promise<User> {
    return await API.post(`users`, data);
  }

  static async resetPassword(email: string): Promise<void> {
    return await API.post(`users/resetPassword`, { email });
  }

  static async verifyResetPassword(
    password: string,
    token: string
  ): Promise<void> {
    return await API.post(`users/verifyResetPassword`, {
      password,
      token,
    });
  }

  static async verify(token: string): Promise<void> {
    return await API.post(`users/verify`, { token });
  }

  static async resendVerification(username: string): Promise<void> {
    return await API.post(`users/resendVerification`, {
      username,
    });
  }
}
