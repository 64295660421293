import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

export const AppLoading = () => (
  <div
    style={{
      textAlign: 'center',
      padding: '5em',
      backgroundColor: '#fff',
      height: '100vh',
      color: '#fff',
      justifyItems: 'center',
      alignItems: 'center',
      display: 'flex',
    }}
  >
    <div
      style={{
        textAlign: 'center',
        display: 'block',
        width: '100%',
      }}
    >
      <img src="/assets/images/logo.svg" alt="" width="120" height="120" />
      <br />
      <CircularProgress
        color="inherit"
        style={{
          marginTop: '4em',
        }}
      />
    </div>
  </div>
);
