import './KpiTile.scss';

import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export default function KpiTile({
  color,
  bgColor,
  title,
  image,
  link,
  style,
  externalLink,
}: any) {
  if (!style) style = {};
  style.background = bgColor;
  style.color = color;

  const content = (
    <div className="inner-content">
      {image && <img src={image} alt="" />}
      {title && (
        <div>
          <h3 dangerouslySetInnerHTML={{ __html: title as string }}></h3>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      )}
    </div>
  );

  if (link) {
    return (
      <Link className="KpiTile" style={style} to={link}>
        {content}
      </Link>
    );
  } else if (externalLink) {
    return (
      <a
        href={externalLink}
        target="_blank"
        className="KpiTile"
        style={style}
        rel="noopener noreferrer"
      >
        {content}
      </a>
    );
  } else
    return (
      <div className="KpiTile" style={style}>
        {content}
      </div>
    );
}
