import './Dashboard.scss';
import './Transactions.scss';

import {
  faChevronRight,
  faCommentAltLines,
} from '@fortawesome/pro-regular-svg-icons';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import { uniq } from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, NavLink } from 'react-router-dom';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import Submenu from '../../components/Submenu/Submenu';
import { CashbackShoppingTransaction } from '../../interfaces/cashbackShoppingTransaction';
import { CashbackTicketTransaction } from '../../interfaces/cashbackTicketTransaction';
import { CashbackTravelTransaction } from '../../interfaces/cashbackTravelTransaction';
import { Cashout } from '../../interfaces/cashout';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import { subNavigationItems } from '../../routes';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import CmsManager from '../../services/manager/CmsManager';
import { ContentElements } from '../../interfaces/ContentElements';

interface Props {
  match: any;
  location: any;
  history?: any;
}

interface State {
  cashouts: Cashout[];
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  loading: boolean;
  confirmedCashback: number;
  pendingCashback: number;
  favoriteShops: Shop[];
  transactionsFilter: [];
  type: string;
  page: number;
  status: string;
  yearFilter: string;
  years: number[];

  generalTransactions: GeneralTransaction[];

  onlineTransactions: CashbackShoppingTransaction[];
  onlineCashbackOpen: number;
  onlineCashbackConfirmed: number;
  onlineCashbackCancelled: number;

  travelTransactions: CashbackTravelTransaction[];
  travelCashbackOpen: number;
  travelCashbackConfirmed: number;
  travelCashbackCancelled: number;

  ticketTransactions: CashbackTicketTransaction[];
  ticketCashbackOpen: number;
  ticketCashbackConfirmed: number;
  ticketCashbackCancelled: number;

  content?: ContentElements;
}

interface GeneralTransaction {
  shopName: string;
  date: any;
  cashOutAmount: number;
  price: any;
  status: string;
  category: string;
  highlight?: boolean;
  id?: any;
}

export default class Transactions extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    const params = queryString.parse(this.props.location.search) as any;

    this.state = {
      cashouts: [],
      openComplaints: 0,
      confirmedCashback: 0,
      pendingCashback: 0,
      favoriteShops: [],
      transactionsFilter: [],
      page: 1,
      type: params.type || 'Alle Einkäufe',
      status: params.status || 'Alle Status',
      years: [],
      onlineTransactions: [],
      travelTransactions: [],
      ticketTransactions: [],
      generalTransactions: [],
      yearFilter: 'Alle Jahre',

      onlineCashbackOpen: 0,
      onlineCashbackConfirmed: 0,
      onlineCashbackCancelled: 0,

      travelCashbackOpen: 0,
      travelCashbackConfirmed: 0,
      travelCashbackCancelled: 0,

      ticketCashbackOpen: 0,
      ticketCashbackConfirmed: 0,
      ticketCashbackCancelled: 0,

      loading: true,
    };

    this.handleChangeStatusFilter = this.handleChangeStatusFilter.bind(this);
    this.handleChangeTypeFilter = this.handleChangeTypeFilter.bind(this);
    this.handleChangeYearsFilter = this.handleChangeYearsFilter.bind(this);
  }

  handleChangeStatusFilter(event: any) {
    this.setState(
      {
        status: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeTypeFilter(event: any) {
    this.setState(
      {
        type: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeYearsFilter(event: any) {
    this.setState(
      {
        yearFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  async load() {
    this.setState({ loading: true });

    const startDate =
      this.state.yearFilter !== 'Alle Jahre'
        ? '01-01-' + this.state.yearFilter
        : '';
    const endDate =
      this.state.yearFilter !== 'Alle Jahre'
        ? '31-12-' + this.state.yearFilter
        : '';

    const result = await Promise.all([
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getOnlineTransactions(startDate, endDate),
      CashbackManager.getTravelTransactions(startDate, endDate),
      // CashbackManager.getTicketTransactions(startDate, endDate),
      CashbackManager.getCashouts(startDate, endDate),
    ]);

    const sumsOnline = await CashbackManager.getCashbackAmount(result[2].items);
    const sumsTravel = await CashbackManager.getCashbackAmount(result[3].items);
    const sumsTicket = await CashbackManager.getCashbackAmount(result[4].items);

    let normalizedOnlineTransactions: any = [];
    let normalizedTravelTransactions: any = [];
    let normalizedTicketTransactions: any = [];
    let normalizedCashouts: any = [];

    if (result[2]) {
      normalizedOnlineTransactions = [
        ...result[2].items.map((item: CashbackShoppingTransaction) => {
          return {
            category: 'Online Cashback',
            shopName: item.shopName || '',
            date: item.date || '',
            cashOutAmount: item.cashOutAmount,
            price: item.price || -1,
            status: item.status || '',
            id: item.id,
          } as GeneralTransaction;
        }),
      ];
    }

    if (result[3]) {
      normalizedTravelTransactions = [
        ...result[3].items.map((item: CashbackTravelTransaction) => {
          return {
            category: 'Reiserückvergütung',
            shopName: item.destination || '',
            date: item.departureDate || '',
            cashOutAmount: item.cashOutAmount || -1,
            price: item.price || -1,
            status: item.status || '',
          } as GeneralTransaction;
        }),
      ];
    }

    // if (result[4]) {
    //   normalizedTicketTransactions = [
    //     ...result[4].items.map((item: CashbackTicketTransaction) => {
    //       return {
    //         category: 'Ticketrückvergütung',
    //         shopName: item.event || '',
    //         date: item.date || '',
    //         cashOutAmount: item.cashOutAmount || -1,
    //         price: item.price || -1,
    //         status: item.status || '',
    //       } as GeneralTransaction;
    //     }),
    //   ];
    // }

    if (result[4]) {
      normalizedCashouts = [
        ...result[4].items.map((item: Cashout) => {
          return {
            category: 'Auszahlung',
            shopName: '-',
            date: item.date || '',
            cashOutAmount: Helper.formatNumber(item.sum, 2) || -1,
            price: '-',
            status: item.status || '',
          } as GeneralTransaction;
        }),
      ];
    }

    let generalTransactions = [
      ...normalizedOnlineTransactions,
      ...normalizedTravelTransactions,
      ...normalizedTicketTransactions,
      ...normalizedCashouts,
    ];

    let years = [
      ...generalTransactions.map(transaction =>
        new Date(transaction.date).getFullYear()
      ),
    ];

    years = uniq(years);

    // Save filter state
    StateManager.setState('transactions', {
      type: this.state.type,
      page: this.state.page,
      status: this.state.status,
      yearFilter: this.state.yearFilter,
    });

    if (this.state.status !== 'Alle Status' && this.state.status !== 'open') {
      normalizedOnlineTransactions = normalizedOnlineTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );

      normalizedTravelTransactions = normalizedTravelTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );

      normalizedTicketTransactions = normalizedTicketTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );

      normalizedCashouts = normalizedCashouts.filter(
        (transaction: any) => transaction.status === this.state.status
      );

      generalTransactions = generalTransactions.filter(
        (transaction: any) => transaction.status === this.state.status
      );
    }

    if (this.state.status === 'open') {
      normalizedOnlineTransactions = normalizedOnlineTransactions.filter(
        (transaction: any) =>
          transaction.status === 'open' || transaction.status === 'confirmed'
      );

      normalizedTravelTransactions = normalizedTravelTransactions.filter(
        (transaction: any) =>
          transaction.status === 'open' || transaction.status === 'confirmed'
      );

      normalizedTicketTransactions = normalizedTicketTransactions.filter(
        (transaction: any) =>
          transaction.status === 'open' || transaction.status === 'confirmed'
      );

      normalizedCashouts = normalizedCashouts.filter(
        (transaction: any) =>
          transaction.status === 'open' || transaction.status === 'confirmed'
      );

      generalTransactions = generalTransactions.filter(
        (transaction: any) =>
          transaction.status === 'open' || transaction.status === 'confirmed'
      );
    }

    const content = await CmsManager.getPageContentsCleaned('transaktionen');

    this.setState({
      cashoutProfile: result[0],
      openComplaints: result[1],
      onlineTransactions: normalizedOnlineTransactions,
      travelTransactions: normalizedTravelTransactions,
      onlineCashbackOpen: sumsOnline.sumOpen,
      onlineCashbackConfirmed: sumsOnline.sumCollected,
      onlineCashbackCancelled: sumsOnline.sumCancelled,
      travelCashbackOpen: sumsTravel.sumOpen,
      travelCashbackConfirmed: sumsTravel.sumCollected,
      travelCashbackCancelled: sumsTravel.sumCancelled,
      ticketCashbackOpen: sumsTicket.sumOpen,
      ticketCashbackConfirmed: sumsTicket.sumCollected,
      ticketCashbackCancelled: sumsTicket.sumCancelled,
      cashouts: result[4].items,
      years,
      generalTransactions,
      content,
      loading: false,
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.load();
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gesamtübersicht - MLP Cashback</title>
        </Helmet>
        <div className="Transactions">
          {this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout>
                <Loading />
              </InnerLayout>
            </div>
          )}

          {!this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />

              <InnerLayout>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Gesamtguthaben"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt offen</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.openCash || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt verfügbar</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.bankBalance || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt ausgezahlt</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.paidOutCash +
                                      this.state.cashoutProfile
                                        .externalPaidOutCash || 0
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Cashback bestätigt"
                        link="/gesamtuebersicht?status=collected"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Online Cashback</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.onlineCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Reiserückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.travelCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div>
                          {/* <div className="dataRow">
                            <div className="dataCell">
                              <p>Ticketrückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.ticketCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Nachbuchungsanfragen"
                        link="/nachbuchungsanfragen"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>offene Anfragen</p>
                            </div>

                            <div className="dataCell">
                              <p>{this.state.openComplaints || 0}</p>
                            </div>
                          </div>

                          {/* TODO: get proper value */}
                          {/* <div className="dataRow">
                            <div className="dataCell">
                              <p>Cashbackwert</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.confirmedCashback || 0
                                )}
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </DashboardBox>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Headline type="h3" styling="thin">
                          Meine Gesamtübersicht
                        </Headline>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="tableFilter">
                        <div>
                          <span className="filter-label">Kategorie</span>

                          <Select
                            className="rounded-select"
                            name="type"
                            value={this.state.type}
                            onChange={this.handleChangeTypeFilter}
                          >
                            <MenuItem value="Alle Einkäufe">
                              Alle Einkäufe
                            </MenuItem>
                            <MenuItem value="cashback">
                              Online Cashback
                            </MenuItem>
                            <MenuItem value="travel">
                              Reiserückvergütung
                            </MenuItem>
                            {/* <MenuItem value="ticket">
                              Ticketrückvergütung
                            </MenuItem> */}
                            <MenuItem value="cashouts">Auszahlungen</MenuItem>
                          </Select>
                        </div>

                        <div>
                          <span className="filter-label">Status</span>

                          <Select
                            className="rounded-select"
                            name="status"
                            value={this.state.status}
                            onChange={this.handleChangeStatusFilter}
                          >
                            <MenuItem value="Alle Status">Alle Status</MenuItem>
                            <MenuItem value="open">Offen</MenuItem>
                            <MenuItem value="collected">Bestätigt</MenuItem>
                            <MenuItem value="canceled">Storniert</MenuItem>
                          </Select>
                        </div>

                        <div>
                          <span className="filter-label">Zeitraum</span>

                          <Select
                            className="rounded-select"
                            name="years"
                            value={this.state.yearFilter}
                            onChange={this.handleChangeYearsFilter}
                          >
                            <MenuItem value="Alle Jahre">Alle Jahre</MenuItem>

                            {this.state.years.map((year, key) => (
                              <MenuItem key={key} value={year}>
                                {year}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>

                      {this.state.type === 'Alle Einkäufe' && (
                        <ResponsiveTable
                          options={{
                            pagination: true,
                          }}
                          columns={[
                            {
                              name: 'category',
                              label: 'Kategorie',
                              textAlign: 'left',
                            },
                            {
                              name: 'status',
                              label: 'Status',
                              sortable: true,
                              notCollapseOnMobile: true,
                              customBodyRender: value => (
                                <div>
                                  {value === 'confirmed' && 'Offen'}
                                  {value === 'collected' && 'Bestätigt'}
                                  {value === 'canceled' && 'Storniert'}
                                  {value === 'cancelled' && 'Storniert'}
                                  {value === 'open' && 'Offen'}
                                </div>
                              ),
                              textAlign: 'left',
                            },
                            {
                              name: 'shopName',
                              label: 'Shop/Reiseziel',
                              textAlign: 'left',
                              sortable: true,
                              notCollapseOnMobile: true,
                            },
                            {
                              name: 'date',
                              label: 'Datum',
                              notCollapseOnMobile: true,
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              sortable: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'price',
                              label: 'Preis',
                              sortable: true,
                              textAlign: 'right',
                            },
                            {
                              name: 'cashOutAmount',
                              label: 'Cashback',
                              sortable: true,
                              textAlign: 'right',
                            },
                            {
                              name: 'action',
                              label: 'Rückfrage',
                              textAlign: 'right',
                              customBodyRender: (value, entry) =>
                                value ? (
                                  <Link
                                    to={{
                                      pathname: '/gesamtuebersicht/reklamation',
                                      state: {
                                        shopId: value.id,
                                        shopName: value.shopName,
                                      },
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faCommentDots} />
                                  </Link>
                                ) : (
                                  <span></span>
                                ),
                            },
                          ]}
                          data={
                            this.state.generalTransactions &&
                            this.state.generalTransactions
                              .map((transaction: GeneralTransaction) => {
                                if (transaction.category === 'Auszahlung') {
                                  return {
                                    category: transaction.category,
                                    shopName: '-',
                                    date: transaction.date,
                                    cashOutAmount: Helper.formatPrice(
                                      transaction.cashOutAmount,
                                      undefined,
                                      true
                                    ),
                                    price: '-',
                                    status: transaction.status,
                                    action: '',
                                    highlight: true,
                                  };
                                } else {
                                  return {
                                    category: transaction.category,
                                    shopName: transaction.shopName,
                                    date: transaction.date,
                                    cashOutAmount: Helper.formatPrice(
                                      transaction.cashOutAmount
                                    ),
                                    price: Helper.formatPrice(
                                      transaction.price
                                    ),
                                    status: transaction.status,
                                    action:
                                      transaction.category === 'Online Cashback'
                                        ? {
                                            id: transaction.id,
                                            shopName: transaction.shopName,
                                          }
                                        : undefined,
                                  };
                                }
                              })
                              .filter(a => !!a.shopName)
                          }
                          footer={{
                            label: `Gesamt bestätigt ${Helper.formatDate(
                              new Date()
                            )}`,
                            value: Helper.formatPrice(
                              this.state.onlineCashbackConfirmed +
                                this.state.travelCashbackConfirmed
                            ),
                          }}
                        />
                      )}

                      {this.state.type === 'cashback' && (
                        <ResponsiveTable
                          options={{
                            pagination: true,
                          }}
                          columns={[
                            {
                              name: 'category',
                              label: 'Kategorie',
                              textAlign: 'left',
                            },
                            {
                              name: 'status',
                              label: 'Status',
                              sortable: true,
                              notCollapseOnMobile: true,
                              customBodyRender: value => (
                                <div>
                                  {value === 'confirmed' && 'Offen'}
                                  {value === 'collected' && 'Bestätigt'}
                                  {value === 'canceled' && 'Storniert'}
                                  {value === 'cancelled' && 'Storniert'}
                                  {value === 'open' && 'Offen'}
                                </div>
                              ),
                              textAlign: 'left',
                            },
                            {
                              name: 'shopName',
                              label: 'Shop',
                              sortable: true,
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'date',
                              label: 'Datum',
                              sortable: true,
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'price',
                              label: 'Einkaufswert',
                              sortable: true,
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              textAlign: 'right',
                            },
                            {
                              name: 'cashOutAmount',
                              label: 'Cashback',
                              sortable: true,
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              textAlign: 'right',
                            },
                            {
                              name: 'action',
                              label: 'Aktion',
                              customBodyRender: (value, entry) => (
                                <Link
                                  to={{
                                    pathname: '/gesamtuebersicht/reklamation',
                                    state: {
                                      shopId: value.id,
                                      shopName: value.shopName,
                                    },
                                  }}
                                >
                                  <FontAwesomeIcon icon={faCommentDots} />
                                </Link>
                              ),
                              textAlign: 'left',
                            },
                          ]}
                          data={this.state.onlineTransactions
                            .map((transaction: CashbackShoppingTransaction) => {
                              console.log('Transaction', transaction);
                              return {
                                category: transaction.category,
                                shopName: transaction.shopName,
                                date: transaction.date,
                                cashOutAmount: transaction.cashOutAmount,
                                price: transaction.price,
                                status: transaction.status,
                                action: {
                                  id: transaction.id,
                                  shopName: transaction.shopName,
                                },
                              };
                            })
                            .filter(a => !!a.shopName)}
                          footer={{
                            label: `Gesamt bestätigt (Online Cashback) ${Helper.formatDate(
                              new Date()
                            )}`,
                            value: Helper.formatPrice(
                              this.state.onlineCashbackConfirmed || 0
                            ),
                          }}
                        />
                      )}

                      {this.state.type === 'travel' && (
                        <ResponsiveTable
                          options={{
                            pagination: true,
                          }}
                          columns={[
                            {
                              name: 'category',
                              label: 'Kategorie',
                              textAlign: 'left',
                            },
                            {
                              name: 'status',
                              label: 'Status',
                              notCollapseOnMobile: true,
                              customBodyRender: value => (
                                <div>
                                  {value === 'confirmed' && 'Offen'}
                                  {value === 'collected' && 'Bestätigt'}
                                  {value === 'canceled' && 'Storniert'}
                                  {value === 'cancelled' && 'Storniert'}
                                  {value === 'open' && 'Offen'}
                                </div>
                              ),
                              sortable: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'shopName',
                              label: 'Reiseziel',
                              sortable: true,
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'date',
                              label: 'Datum',
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              sortable: true,
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'price',
                              label: 'Preis',
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              sortable: true,
                              textAlign: 'right',
                            },
                            {
                              name: 'cashOutAmount',
                              label: 'Cashback',
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              sortable: true,
                              textAlign: 'right',
                            },
                          ]}
                          data={this.state.travelTransactions.map(
                            (transaction: CashbackTravelTransaction) => {
                              return {
                                category: transaction.category,
                                shopName: transaction.shopName,
                                date: transaction.date,
                                cashOutAmount: transaction.cashOutAmount,
                                price: transaction.price,
                                status: transaction.status,
                              };
                            }
                          )}
                          footer={{
                            label: `Gesamt bestätigt (Reiserückvergütung) ${Helper.formatDate(
                              new Date()
                            )}`,
                            value: Helper.formatPrice(
                              this.state.travelCashbackConfirmed || 0
                            ),
                          }}
                        />
                      )}

                      {this.state.type === 'ticket' && (
                        <ResponsiveTable
                          options={{
                            pagination: true,
                          }}
                          columns={[
                            {
                              name: 'category',
                              label: 'Kategorie',
                              textAlign: 'left',
                            },
                            {
                              name: 'cashOutAmount',
                              label: 'Cashback',
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              sortable: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'shopName',
                              label: 'Veranstaltung',
                              sortable: true,
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'date',
                              label: 'Datum',
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              sortable: true,
                              notCollapseOnMobile: true,
                              textAlign: 'left',
                            },
                            {
                              name: 'price',
                              label: 'Preis',
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              sortable: true,
                              textAlign: 'right',
                            },
                            {
                              name: 'cashOutAmount',
                              label: 'Cashback',
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              sortable: true,
                              textAlign: 'right',
                            },
                          ]}
                          data={this.state.ticketTransactions.map(
                            (transaction: CashbackTicketTransaction) => {
                              return {
                                category: transaction.category,
                                shopName: transaction.event,
                                date: transaction.date,
                                cashOutAmount: transaction.cashOutAmount,
                                price: transaction.price,
                                status: transaction.status,
                              };
                            }
                          )}
                          footer={{
                            label: `Gesamt bestätigt (Ticketrückvergütung) ${Helper.formatDate(
                              new Date()
                            )}`,
                            value: Helper.formatPrice(
                              this.state.ticketCashbackConfirmed || 0
                            ),
                          }}
                        />
                      )}

                      {this.state.type === 'cashouts' &&
                        this.state.cashoutProfile && (
                          <ResponsiveTable
                            options={{
                              pagination: true,
                            }}
                            columns={[
                              {
                                name: 'category',
                                label: 'Kategorie',
                                textAlign: 'left',
                              },
                              {
                                name: 'status',
                                label: 'Status',
                                sortable: true,
                                notCollapseOnMobile: true,
                                customBodyRender: value => (
                                  <div>
                                    {Helper.getLabelForCashoutStatus(value)}
                                  </div>
                                ),
                                textAlign: 'left',
                              },
                              {
                                name: 'shopName',
                                label: 'Name',
                                textAlign: 'left',
                                sortable: true,
                                notCollapseOnMobile: true,
                              },
                              {
                                name: 'date',
                                label: 'Datum',
                                notCollapseOnMobile: true,
                                customBodyRender: (value: any) =>
                                  Helper.formatDate(value),
                                sortable: true,
                                textAlign: 'left',
                              },
                              {
                                name: 'price',
                                label: 'Preis',
                                sortable: true,
                                textAlign: 'right',
                              },
                              {
                                name: 'cashOutAmount',
                                label: 'Cashback',
                                sortable: true,
                                textAlign: 'right',
                              },
                            ]}
                            data={this.state.cashouts.map(
                              (transaction: Cashout) => {
                                return {
                                  category: 'Auszahlung',
                                  shopName: '-',
                                  date: transaction.date,
                                  cashOutAmount: Helper.formatPrice(
                                    transaction.sum,
                                    undefined,
                                    true
                                  ),
                                  price: '-',
                                  status: transaction.status,
                                  action: '',
                                  highlight: true,
                                };
                              }
                            )}
                            footer={{
                              label: `Gesamt Auszahlungen ${Helper.formatDate(
                                new Date()
                              )}`,
                              value: Helper.formatPrice(
                                this.state.cashoutProfile.paidOutCash +
                                  this.state.cashoutProfile
                                    .externalPaidOutCash || 0,
                                undefined,
                                true
                              ),
                            }}
                          />
                        )}
                    </Grid>
                  </Grid>
                </Box>

                {this.state.content &&
                  this.state.content.offenTitle &&
                  this.state.content.offen &&
                  this.state.content.bestatigtTitle &&
                  this.state.content.bestatigt &&
                  this.state.content.storniertTitle &&
                  this.state.content.storniert && (
                    <Box style={{ marginTop: 50 }}>
                      <Grid container spacing={2}>
                        <Grid item lg={4}>
                          <Headline type="h4">
                            {this.state.content.offenTitle}
                          </Headline>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.content.offen as string,
                            }}
                          />
                        </Grid>

                        <Grid item lg={4}>
                          <Headline type="h4">
                            {this.state.content.bestatigtTitle}
                          </Headline>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.content.bestatigt as string,
                            }}
                          />
                        </Grid>

                        <Grid item lg={4}>
                          <Headline type="h4">
                            {this.state.content.storniertTitle}
                          </Headline>

                          <div
                            dangerouslySetInnerHTML={{
                              __html: this.state.content.storniert as string,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
              </InnerLayout>

              <Box color="blue">
                <InnerLayout>
                  <Box
                    color="transparent"
                    className="noHorizontalPaddingOnMobile"
                  >
                    <Grid container spacing={4}>
                      <Grid
                        item
                        xs={12}
                        xl={8}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <Headline type="h4">
                          Sie finden eine Transaktion nicht?
                        </Headline>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        xl={8}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <p style={{ marginTop: 0 }}>
                          Bitte beachten Sie, dass es in manchen Fällen bis zu
                          90 Tage dauern kann, bis Ihre Zahlung in der
                          Transaktionsliste erscheint. Sollte die Buchung
                          dennoch nicht angezeigt werden, haben Sie die
                          Möglichkeit eine Reklamation anzufragen.
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        xl={4}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <NavLink to="/nachbuchungsanfragen/neue-nachbuchungsanfrage">
                          <Button variant="contained" color="primary">
                            Nachbuchungsanfrage stellen
                          </Button>
                        </NavLink>
                      </Grid>
                    </Grid>
                  </Box>
                </InnerLayout>
              </Box>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
