import './Travel.scss';

import { Grid } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import IntroSection from '../../components/IntroSection/IntroSection';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { CmsFile } from '../../interfaces/cmsFile';
import { ContentElements } from '../../interfaces/ContentElements';
import { DownloadLink } from '../../interfaces/downloadLink';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import CmsManager from './../../services/manager/CmsManager';

interface State {
  cashbackShops: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContent: string | DownloadLink[] | CmsFile[];
  titelBilder?: any;
}

interface Props {}

export default class TravelBooking extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      loading: true,
      sliderContent: '',
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const cashbackShops = await CashbackManager.findShopByCategoryId(
      '13',
      1,
      4
    );

    const content = await CmsManager.getPageContentsCleaned('reisen');

    let titelBilder: any = [];

    if (content.titelbilderFiles && content.titelbilderFiles.length > 0) {
      (content.titelbilderFiles as CmsFile[]).forEach((file: CmsFile) => {
        titelBilder = [...titelBilder, { src: file.url }];
      });
    }

    const sliderContent = content.reiseslidernichteingeloggt;

    this.setState({
      cashbackShops: cashbackShops.items,
      content,
      loading: false,
      sliderContent,
      titelBilder,
    });

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  render() {
    return (
      <Layout>
        <div className="Travel">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reisebuchung - MLP Cashback</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading &&
            this.state.content &&
            !this.state.sliderContent && (
              <div>
                {this.state.titelBilder &&
                  this.state.titelBilder.length > 0 && (
                    <ImageSlider images={this.state.titelBilder} />
                  )}
                <InnerLayout>
                  <Box
                    style={{
                      paddingTop: 50,
                      paddingBottom: 50,
                    }}
                  >
                    <IntroSection
                      headline={this.state.content?.pageTitle as string}
                      text={this.state.content?.pageDescription as string}
                      fkn={
                        Auth.isLoggedIn()
                          ? this.state.user?.userInfo._aditoBasicContractId
                          : ''
                      }
                      contactInformation={{
                        phoneNumber: this.state.content?.phonenumberSubtitle,
                        serviceHours: [
                          {
                            days: 'Montag bis Freitag',
                            from: this.state.content?.servicemonfrifromSubtitle,
                            to: this.state.content?.servicemonfritoSubtitle,
                          },
                          {
                            days: 'Samstag',
                            from: this.state.content?.servicesatfromSubtitle,
                            to: this.state.content?.servicesattoSubtitle,
                          },
                        ],
                      }}
                    />
                  </Box>
                  <Box style={{ paddingBottom: 0 }}>
                    <Backlink
                      label="zurück zu Reisen"
                      to="/reisen"
                      style={{ paddingBottom: 0 }}
                    />
                  </Box>
                  {Auth.isLoggedIn() && (
                    <Box>
                      <Grid container spacing={2}>
                        <iframe
                          style={{
                            width: '100%',
                            height: 3000,
                            border: 'none',
                          }}
                          src={`https://buchung.reisewerk24.de/search/region?taid=mlp-cashback&colors=p-073d5d,pl-f1f6f9,pd-073d5d,a-e89e41,al-ededed,ad-ededed,c-e89e41,cd-e89e41?agent=${Auth.getFKN()}`}
                        ></iframe>
                      </Grid>
                    </Box>
                  )}
                  {!Auth.isLoggedIn() && (
                    <Box>
                      <div style={{ position: 'relative' }}>
                        <img
                          src="/assets/images/travel-blurred.jpg"
                          alt=""
                          style={{
                            width: '100%',
                          }}
                        />

                        <div
                          style={{
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            left: 0,
                            top: 100,
                            right: 0,
                          }}
                        >
                          <div className="loginRequired">
                            <Headline type="h2" color="white">
                              Bitte einloggen
                            </Headline>

                            <p style={{ color: '#ffffff' }}>
                              Bitte loggen Sie sich ein, damit Sie sich Ihr
                              Cashback bei der Reisebuchung sichern können.
                              Anschließend haben Sie die Möglichkeit die
                              gewünschte Reise auszuwählen und zu buchen.
                            </p>

                            <AuthButton
                              color="primary"
                              variant="contained"
                              size="medium"
                              style={{
                                alignSelf: 'flex-end',
                                marginTop: '2em',
                              }}
                            >
                              Login
                            </AuthButton>
                          </div>
                        </div>
                      </div>
                    </Box>
                  )}
                </InnerLayout>
              </div>
            )}
        </div>
      </Layout>
    );
  }
}
