import './CashbackFilterBox.scss';

import { Button, Grid } from '@material-ui/core';
import React, { Fragment } from 'react';

import Box from '../Box/Box';
import Headline from '../Headline/Headline';
import InputWithButton from '../InputWithButton/InputWithButton';

interface Props {
  children?: any;
  title?: string;
  subTitle?: string;
  placeholder: string;
  buttonLabel: string;
  onSubmit: () => void;
  onResetFilter: () => void;
  onChange: (query: string) => void;
  query?: string;
  categoryFilter?: any;
  shopFilter?: any;
  quickFilter?: any;
  sortingSelect?: any;
  priceRangeFilter?: any;
  filterRowGrid: { left: any; right: any };
  showReset: boolean;
}

export default function CashbackFilterBox(props: Props) {
  return (
    <Box className="CashbackFilterBox" style={{ padding: '0 0 20px 0' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props.title && (
            <Headline type="h1" size="small">
              {props.title}
            </Headline>
          )}
          {props.subTitle && <p>{props.subTitle}</p>}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Fragment>
                <div className="filter-label">
                  <span>Suche</span>
                </div>
                <InputWithButton
                  value={props.query}
                  placeholder={props.placeholder}
                  buttonLabel={props.buttonLabel}
                  onClick={props.onSubmit}
                  onChange={(e: any) => props.onChange(e.target.value)}
                  onKeyDown={(event: any) =>
                    event.keyCode === 13 ? props.onSubmit() : null
                  }
                  buttonType="iconButton"
                />
              </Fragment>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {props.categoryFilter && (
                <Fragment>
                  <div className="filter-label">
                    <span>Kategorie</span>
                  </div>
                  {props.categoryFilter}
                </Fragment>
              )}
              {props.shopFilter && (
                <Fragment>
                  <div className="filter-label">
                    <span>Shops</span>
                  </div>
                  {props.shopFilter}
                </Fragment>
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {props.sortingSelect && (
                <Fragment>
                  <div className="filter-label">
                    <span>Sortierung</span>
                  </div>
                  {props.sortingSelect}
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className="filter-section">
          <Grid container spacing={2}>
            <Grid item xs={12} md={props.filterRowGrid.left}>
              {props.quickFilter && <Fragment>{props.quickFilter}</Fragment>}
              {props.priceRangeFilter && (
                <Fragment>{props.priceRangeFilter}</Fragment>
              )}
            </Grid>

            <Grid item xs={12} md={props.filterRowGrid.right}>
              <div className="resetFilterWrapper">
                {props.showReset === true && (
                  <Button onClick={props.onResetFilter} color="primary">
                    Filter&nbsp;zurücksetzen
                  </Button>
                )}

                <Button
                  color="primary"
                  variant="contained"
                  onClick={props.onSubmit}
                >
                  Suchen
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
