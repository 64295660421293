import React from 'react';
import { Helmet } from 'react-helmet';

import Accordion from '../../components/Accordion/Accordion';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import { DownloadLink } from '../../interfaces/downloadLink';
import Helper from '../../services/helper';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  history: any;
}

interface FaqItem {
  title: string | DownloadLink[];
  content: string | DownloadLink[];
}

interface State {
  loading: boolean;
  content?: ContentElements;
  faqGeneral?: ContentElements;
  faqTravel?: ContentElements;
  faqBonusRadar?: ContentElements;
  faqGeneralItems: FaqItem[];
  faqTravelItems: FaqItem[];
  faqBonusRadarItems: FaqItem[];
}

export default class Faq extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      faqGeneralItems: [],
      faqTravelItems: [],
      faqBonusRadarItems: [],
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const contents = await Promise.all([
      CmsManager.getPageContentsCleaned('faq'),
      CmsManager.getPageContentsCleaned('faq-allgemein'),
      CmsManager.getPageContentsCleaned('faq-reise-cashback'),
      CmsManager.getPageContentsCleaned('faq-bonusradar'),
    ]);

    contents.forEach((page, index) => {
      if (index !== 0) {
        Object.keys(page).forEach((item: string) => {
          if (
            item !== 'pageTitle' &&
            item !== 'pageDescription' &&
            item.endsWith('Title') &&
            typeof item === 'string'
          ) {
            switch (index) {
              case 1:
                this.setState({
                  faqGeneralItems: [
                    ...this.state.faqGeneralItems
                    ,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
                
                case 2:
                  this.setState({
                    faqTravelItems: [
                      ...this.state.faqTravelItems,
                      {
                        title: page[item] as string,
                        content: page[item.slice(0, -5)] as string,
                      },
                    ],
                  });
                break;

                case 3:
                  this.setState({
                    faqBonusRadarItems: [
                      ...this.state.faqBonusRadarItems,
                      {
                        title: page[item] as string,
                        content: page[item.slice(0, -5)] as string,
                      },
                    ],
                  });
                break;
            }
          }

          return true;
        });
      }
    });

    this.setState({
      loading: false,
      content: contents[0],
      faqGeneral: contents[1],
      faqTravel: contents[2],
      faqBonusRadar: contents[3],
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>FAQ - MLP Cashback</title>
        </Helmet>

        <div className="PlusServices">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <div>
              <InnerLayout>
                {this.state.content.pageTitle && (
                  <Box>
                    <Headline type="h1" styling="thin">
                      {this.state.content.pageTitle}
                    </Headline>

                    {this.state.content.pageDescription && (
                      <div style={{ width: '80%' }}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: this.state.content
                              .pageDescription as string,
                          }}
                          onClick={(e: any) =>
                            Helper.clickHandler(e, this.props.history)
                          }
                        ></p>
                      </div>
                    )}
                  </Box>
                )}

                {this.state.faqGeneral && this.state.faqGeneralItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqGeneral.pageTitle}
                    </Headline>

                    <Accordion items={this.state.faqGeneralItems}></Accordion>
                  </Box>
                  )}

                {this.state.faqTravel && this.state.faqTravelItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqTravel.pageTitle}
                    </Headline>

                    <Accordion items={this.state.faqTravelItems}></Accordion>
                  </Box>
                  )}
                  
                {this.state.faqBonusRadar && this.state.faqBonusRadarItems && (
                  <Box>
                    <Headline type="h2">
                      {this.state.faqBonusRadar.pageTitle}
                    </Headline>

                    <Accordion items={this.state.faqBonusRadarItems}></Accordion>
                  </Box>
                  )}
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
