import './Dashboard.scss';

import { faCommentAltLines } from '@fortawesome/pro-light-svg-icons';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import Submenu from '../../components/Submenu/Submenu';
import { CashbackComplaint } from '../../interfaces/cashbackComplaint';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import { subNavigationItems } from '../../routes';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  match: any;
  location: any;
  history: any;
}

interface State {
  cashoutProfile?: CashoutProfile;
  complaints: CashbackComplaint[];
  confirmedCashback?: number;
  pendingCashback?: number;
  travelCashbackConfirmed?: number;
  ticketCashbackConfirmed?: number;
  openComplaints?: number;
  loading: boolean;
  hasClickouts: boolean;
  status: string;
  page: number;
  content?: ContentElements;
}

export default class Complaints extends React.Component<Props, State> {
  public subNavigation = subNavigationItems;

  constructor(props: Props) {
    super(props);

    this.state = {
      complaints: [],
      loading: true,
      hasClickouts: false,
      status: 'Alle Status',
      page: 1,
    };

    this.handleChangeStatusFilter = this.handleChangeStatusFilter.bind(this);
  }

  async load() {
    this.setState({
      loading: true,
    });

    const result = await Promise.all([CashbackManager.findComplaints()]);

    let complaints = result[0].items;

    if (this.state.status !== 'Alle Status') {
      complaints = complaints.filter(
        (complaint: any) => complaint.status === this.state.status
      );
    }

    this.setState({
      complaints,
      loading: false,
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const result = await Promise.all([
      CashbackManager.getCashoutProfile(),
      CashbackManager.findComplaints(),
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.findLastClickoutShops(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getTicketTransactions(),
    ]);

    const complaints = result[1].items;
    const shopPromises: any[] = [];
    const shops = await Promise.all(shopPromises);
    const clickoutShops = result[3];

    if (clickoutShops.length > 0) {
      this.setState({
        hasClickouts: true,
      });
    }

    // Get shop objects to fetch name
    if (complaints && complaints.length > 0) {
      complaints.forEach((complaint: CashbackComplaint) => {
        shopPromises.push(CashbackManager.findShop(complaint.shopId));

        shops.forEach((shop: Shop) => {
          if (shop.id === complaint.shopId) {
            complaint.shopName = shop.name;
          }
        });
      });
    }

    const [sumsTravel, sumsTicket, content] = await Promise.all([
      CashbackManager.getCashbackAmount(result[4].items),
      CashbackManager.getCashbackAmount(result[6].items),
      CmsManager.getPageContentsCleaned('complaints'),
    ]);

    this.setState({
      cashoutProfile: result[0],
      complaints,
      confirmedCashback: result[2].confirmed,
      pendingCashback: result[2].open,
      travelCashbackConfirmed: sumsTravel.sumCollected,
      ticketCashbackConfirmed: sumsTicket.sumCollected,
      openComplaints: result[5],
      content,
      loading: false,
    });

    this.load();
  }

  handleChangeStatusFilter(event: any) {
    this.setState(
      {
        status: event.target.value,
      },
      () => {
        this.load();
      }
    );
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Nachbuchungsanfragen - MLP Cashback</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />
              <InnerLayout>
                <Loading />
              </InnerLayout>
            </div>
          )}

          {!this.state.loading && (
            <div>
              <Submenu items={this.subNavigation} />

              <InnerLayout>
                <Box style={{ paddingBottom: '60px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Gesamtguthaben"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt offen</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.openCash || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt verfügbar</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.bankBalance || 0
                                  )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Gesamt ausgezahlt</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {this.state.cashoutProfile &&
                                  Helper.formatPrice(
                                    this.state.cashoutProfile.paidOutCash +
                                      this.state.cashoutProfile
                                        .externalPaidOutCash || 0
                                  )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Cashback bestätigt"
                        link="/gesamtuebersicht"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Online Cashback</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.confirmedCashback || 0
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="dataRow">
                            <div className="dataCell">
                              <p>Reiserückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.travelCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div>
                          {/* <div className="dataRow">
                            <div className="dataCell">
                              <p>Ticketrückvergütung</p>
                            </div>

                            <div className="dataCell">
                              <p>
                                {Helper.formatPrice(
                                  this.state.ticketCashbackConfirmed || 0
                                )}
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </DashboardBox>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <DashboardBox
                        title="Nachbuchungsanfragen"
                        link="/nachbuchungsanfragen"
                        icon={faChevronRight}
                      >
                        <div className="dataWrapper">
                          <div className="dataRow">
                            <div className="dataCell">
                              <p>offene Anfragen</p>
                            </div>

                            <div className="dataCell">
                              <p>{this.state.openComplaints || 0}</p>
                            </div>
                          </div>
                        </div>
                      </DashboardBox>
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={8}>
                      <Grid item xs={12}>
                        <Headline type="h3" styling="thin">
                          Meine Nachbuchungsanfragen
                        </Headline>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={4}>
                          <div style={{ marginBottom: 20 }}>
                            <span className="filter-label">Status</span>

                            <Select
                              className="rounded-select"
                              name="status"
                              value={this.state.status}
                              onChange={this.handleChangeStatusFilter}
                            >
                              <MenuItem value="Alle Status">
                                Alle Status
                              </MenuItem>
                              <MenuItem value="open">Offen</MenuItem>
                              <MenuItem value="requested">Angefragt</MenuItem>
                              <MenuItem value="done">Erledigt</MenuItem>
                              <MenuItem value="rejected">Abgelehnt</MenuItem>
                              <MenuItem value="cancelled">Storniert</MenuItem>
                              <MenuItem value="waiting">
                                Warten auf Kunde
                              </MenuItem>
                            </Select>
                          </div>
                        </Grid>
                      </Grid>

                      {this.state.complaints && (
                        <ResponsiveTable
                          options={{
                            pagination: true,
                          }}
                          columns={[
                            {
                              name: 'createdAt',
                              label: 'Datum',
                              sortable: true,
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              textAlign: 'left',
                            },
                            {
                              name: 'shopName',
                              notCollapseOnMobile: true,
                              sortable: true,
                              label: 'Shop',
                              textAlign: 'left',
                            },
                            {
                              name: 'transactionDate',
                              label: 'Einkaufsdatum',
                              sortable: true,
                              customBodyRender: (value: any) =>
                                Helper.formatDate(value),
                              textAlign: 'left',
                            },
                            {
                              name: 'price',
                              label: 'Warenkorbwert',
                              sortable: true,
                              customBodyRender: (value: number) =>
                                Helper.formatPrice(value),
                              textAlign: 'right',
                            },
                            {
                              name: 'status',
                              notCollapseOnMobile: true,
                              sortable: true,
                              label: 'Status',
                              customBodyRender: value => (
                                <div>
                                  <span className="color-bad">
                                    {value === 'requested' && 'Angefragt'}
                                    {value === 'open' && 'Offen'}
                                    {value === 'cancelled' && 'Storniert'}
                                    {value === 'canceled' && 'Storniert'}
                                    {value === 'rejected' && 'Abgelehnt'}
                                  </span>
                                  <span className="color-good">
                                    {value === 'done' && 'Erledigt'}
                                    {value === 'waiting' && 'Wartet auf Kunde'}
                                  </span>
                                </div>
                              ),
                              textAlign: 'right',
                            },
                          ]}
                          data={this.state.complaints.map(
                            (complaint: CashbackComplaint) => {
                              return {
                                createdAt: complaint.createdAt,
                                shopName: complaint.shopName,
                                price: complaint.price,
                                status: complaint.status,
                                transactionDate: complaint.transactionDate,
                              };
                            }
                          )}
                        />
                      )}
                    </Grid>

                    {this.state.hasClickouts === true && (
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ textAlign: 'right', paddingTop: 10 }}
                        >
                          <Link to="/nachbuchungsanfragen/neue-nachbuchungsanfrage">
                            <Button
                              color="primary"
                              variant="contained"
                              size="medium"
                            >
                              <FontAwesomeIcon
                                icon={faCommentAltLines}
                                style={{
                                  marginRight: 10,
                                }}
                              />
                              Neue Nachbuchungsanfrage
                            </Button>
                          </Link>
                        </Grid>
                        <Grid container spacing={4} style={{ marginTop: 50 }}>
                          {this.state.content &&
                            this.state.content.open &&
                            this.state.content.openTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.openTitle}
                                </Headline>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content.open as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                          {this.state.content &&
                            this.state.content.requested &&
                            this.state.content.requestedTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.requestedTitle}
                                </Headline>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      .requested as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                          {this.state.content &&
                            this.state.content.waiting &&
                            this.state.content.waitingTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.waitingTitle}
                                </Headline>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      .waiting as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                          {this.state.content &&
                            this.state.content.cancelled &&
                            this.state.content.cancelledTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.cancelledTitle}
                                </Headline>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      .cancelled as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                          {this.state.content &&
                            this.state.content.rejected &&
                            this.state.content.rejectedTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.rejectedTitle}
                                </Headline>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content
                                      .rejected as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                          {this.state.content &&
                            this.state.content.done &&
                            this.state.content.doneTitle && (
                              <Grid item lg={4}>
                                <Headline type="h3">
                                  {this.state.content.doneTitle}
                                </Headline>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content.done as string,
                                  }}
                                  onClick={(e: any) =>
                                    Helper.clickHandler(e, this.props.history)
                                  }
                                ></p>
                              </Grid>
                            )}
                        </Grid>
                      </Grid>
                    )}

                    {this.state.hasClickouts === false && (
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: 'left', paddingTop: 30 }}
                      >
                        <Headline type="h3">
                          Nachbuchungsanfrage erstellen
                        </Headline>

                        <p>
                          Hier können Sie eine Nachbuchungsanfrage stellen,
                          sobald Sie das erste Mal einen Einkauf über die MLP
                          Vorteilswelt getätigt haben.
                        </p>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
