import './Dashboard.scss';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import { Cashout } from '../../interfaces/cashout';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import { Helmet } from 'react-helmet';

interface Props {}

interface State {
  cashouts: Cashout[];
  cashoutProfile?: CashoutProfile;
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutSum: number;
  loading: boolean;
  openComplaints: number;
}

export default class Cashouts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openComplaints: 0,
      cashouts: [],
      cashoutSum: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const result = await Promise.all([
      CashbackManager.findCashouts(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
    ]);

    this.setState({
      cashouts: result[0].items,
      cashoutProfile: result[0],
      openComplaints: result[1],
      confirmedCashback: result[2].confirmed,
      pendingCashback: result[2].open,
      loading: false,
    });
  }

  getCashoutSum() {
    let cashoutSum = 0;

    if (this.state.cashouts) {
      this.state.cashouts.forEach(cashout => {
        cashoutSum += cashout.sum;
      });
    }

    return cashoutSum;
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Auszahlungen - MLP Cashback</title>
        </Helmet>
        <div>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink label="Zurück zur Übersicht" to="/profil" />
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <h1>Ihre Auszahlungen</h1>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <ResponsiveTable
                        lastRowIsSumRow
                        columns={[
                          {
                            name: 'date',
                            label: 'Auszahlungsdatum',
                            textAlign: 'left',
                          },
                          {
                            name: 'sum',
                            label: 'Auszahlungssumme',
                            notCollapseOnMobile: true,
                            textAlign: 'right',
                          },
                        ]}
                        data={
                          this.state.cashouts && [
                            ...this.state.cashouts.map(
                              (cashout: Cashout, index) => ({
                                date: Helper.formatDate(cashout.date),
                                sum: Helper.formatPrice(cashout.sum, 'EUR'),
                              })
                            ),
                            {
                              date: '',
                              sum: `Summe: ${Helper.formatPrice(
                                this.getCashoutSum()
                              )}`,
                            },
                          ]
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Cashback Guthaben"
                          link="/gesamtuebersicht"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>bestätigt</p>
                              </div>

                              <div className="dataCell">
                                <p style={{ fontWeight: 'bold' }}>
                                  {Helper.formatPrice(
                                    this.state.confirmedCashback || 0,
                                    'EUR'
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offen</p>
                              </div>

                              <div className="dataCell">
                                <p style={{ fontWeight: 'bold' }}>
                                  {Helper.formatPrice(
                                    this.state.pendingCashback || 0,
                                    'EUR'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Nachbuchungsanfragen"
                          link="/nachbuchungsanfragen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offene Anfragen</p>
                              </div>

                              <div className="dataCell">
                                <p style={{ fontWeight: 'bold' }}>
                                  {this.state.openComplaints || 0}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
