import './ShopDetail.scss';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCut, faPercent, faStar } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import copyToClipboard from 'copy-to-clipboard';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import InputWithButton from '../../components/InputWithButton/InputWithButton';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Notification from '../../components/Notification/Notification';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import ShopTile from '../../components/ShopTile/ShopTile';
import { CashbackRate } from '../../interfaces/cashbackRate';
import Shop from '../../interfaces/shop';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import { User } from '../../interfaces/user';

interface Props {
  match: any;
  history: any;
  navigation: any;
}

interface State {
  loading: boolean;
  shop?: Shop;
  similarShops?: any;
  visibleVoucherId: number;
  showCopyFeedback: boolean;
  isFavorite: boolean;
  currentFilter: any;
  filteredCashbackRates: any;
  query?: string;
  user?: User;
}

export default class ShopDetail extends React.Component<Props, State> {
  focusListener: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      visibleVoucherId: 0,
      showCopyFeedback: false,
      isFavorite: false,
      currentFilter: {},
      filteredCashbackRates: [],
    };

    this.load = this.load.bind(this);
    this.setVisibleVoucherId = this.setVisibleVoucherId.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
    this.filterCashbackRates = this.filterCashbackRates.bind(this);
  }

  async componentDidMount() {
    await this.load();
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      await this.load();
    }
  }

  async load() {
    const loggedIn = await Auth.checkLogin();
    if (loggedIn) {
      const user = await UserManager.me();
      this.setState({ user });
    }

    const shop = await CashbackManager.findShop(this.props.match.params.id);

    const filteredCashbackRates =
      shop && shop.cashbackRates ? [...shop.cashbackRates] : [];

    const currentFilter = await StateManager.getState('cashback');

    this.setState({
      shop,
      loading: false,
      similarShops: shop.similarShops ? shop.similarShops : undefined,
      isFavorite: shop.isFavorite,
      currentFilter,
      filteredCashbackRates,
    });
  }

  setVisibleVoucherId(id: number) {
    this.setState({
      visibleVoucherId: id,
    });
  }

  copyToClipboard(code?: any) {
    if (!code) return;

    copyToClipboard(code);

    this.setState({
      showCopyFeedback: true,
    });
  }

  async handleToggleFavorite() {
    if (!this.state.shop) return;

    if (this.state.isFavorite) {
      await CashbackManager.removeShopFavorite(this.state.shop.id);
    } else {
      await CashbackManager.setShopFavorite(this.state.shop.id);
    }

    this.setState({
      isFavorite: !this.state.isFavorite,
    });
  }

  filterCashbackRates(query: string) {
    let filtered: CashbackRate[] | undefined;

    if (query.length > 0) {
      query = query.toLowerCase().trim();
      filtered = this.state.shop?.cashbackRates.filter(
        (rate: any): CashbackRate =>
          rate.description.toLowerCase().includes(query)
      );
    } else {
      filtered =
        this.state.shop && this.state.shop.cashbackRates
          ? [...this.state.shop.cashbackRates]
          : [];
    }

    this.setState({
      filteredCashbackRates: filtered,
    });
  }

  render() {
    const renderTableSearch = () => (
      <div className="table-search">
        <InputWithButton
          value={this.state.query}
          onChange={(e: any) => this.filterCashbackRates(e.target.value)}
          buttonType="iconButton"
        />
      </div>
    );

    const hasCashbackRates =
      this.state.filteredCashbackRates &&
      this.state.filteredCashbackRates.length > 0;

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Shopansicht - MLP Cashback</title>
        </Helmet>

        <InnerLayout className="ShopDetail" alternativeBackground>
          {this.state.loading && <Loading />}

          {!this.state.loading && this.state.shop && (
            <Fragment>
              <Box color="transparent" style={{ paddingBottom: 0 }}>
                <Backlink
                  label="Zurück zur Übersicht"
                  state={this.state.currentFilter}
                  to="/cashback/shops"
                />
              </Box>

              <Box color="transparent" style={{ paddingTop: 0 }}>
                <Grid container spacing={2}>
                  <Grid item md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box>
                          <Headline type="h1" size="small">
                            {this.state.shop.name}
                          </Headline>

                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                (this.state.shop.description as string) || '',
                            }}
                            onClick={(e: any) =>
                              Helper.clickHandler(e, this.props.history)
                            }
                          />

                          <AuthButton
                            color="primary"
                            variant="outlined"
                            size="medium"
                            href={
                              this.state.user?.userInfo?.sepa_iban &&
                              this.state.shop.link
                            }
                            target="_blank"
                            style={{ marginTop: '15px' }}
                            onClick={(e: any) =>
                              Helper.handleIbanPopUp(
                                {
                                  iban: this.state.user?.userInfo.sepa_iban,
                                  buttonLink: this.state.shop?.link || '',
                                  shopId: String(this.state.shop?.id),
                                  shopName: String(this.state.shop?.name),
                                  history: this.props.history,
                                },
                                e
                              )
                            }
                          >
                            Zum Shop
                          </AuthButton>
                        </Box>
                      </Grid>

                      {hasCashbackRates && (
                        <Grid item xs={12} id="cashback">
                          <Box>
                            <Headline type="h2" size="small">
                              Cashbackübersicht
                            </Headline>

                            <ResponsiveTable
                              renderSearchField={renderTableSearch}
                              options={{
                                pagination: true,
                              }}
                              columns={[
                                {
                                  name: 'description',
                                  label: 'Beschreibung',
                                  textAlign: 'left',
                                  sortable: true,
                                },
                                {
                                  name: 'value',
                                  label: 'Cashbackwert',
                                  textAlign: 'right',
                                  sortable: true,
                                  // notCollapseOnMobile: true,
                                },
                              ]}
                              data={
                                this.state.filteredCashbackRates &&
                                this.state.filteredCashbackRates.map(
                                  (cashbackRate: any) => ({
                                    description: cashbackRate.description,
                                    value: `${Helper.formatNumber(
                                      cashbackRate.amount,
                                      2
                                    )} ${cashbackRate.type}`,
                                  })
                                )
                              }
                            />
                            <br />
                          </Box>
                        </Grid>
                      )}

                      {this.state.shop.vouchers &&
                        this.state.shop.vouchers.length > 0 && (
                          <Grid item xs={12} id="gutscheine">
                            <Box>
                              <Headline type="h2" size="small">
                                Gutscheine
                              </Headline>

                              {this.state.shop.vouchers.map((voucher, key) => (
                                <div className="ShopDetail-vouchers" key={key}>
                                  <div className="ShopDetail-voucher">
                                    <div className="voucherIntro">
                                      <Headline
                                        type="h3"
                                        color="secondary"
                                        style={{
                                          marginBottom: 0,
                                        }}
                                      >
                                        {voucher.title}
                                      </Headline>

                                      {Auth.isLoggedIn() &&
                                        this.state.visibleVoucherId !==
                                          voucher.id &&
                                        voucher.code && (
                                          <Button
                                            variant="outlined"
                                            size="medium"
                                            color="primary"
                                            onClick={() => {
                                              this.setVisibleVoucherId(
                                                voucher.id
                                              );
                                            }}
                                          >
                                            Gutscheincode anzeigen
                                          </Button>
                                        )}

                                      {Auth.isLoggedIn() &&
                                        this.state.visibleVoucherId ===
                                          voucher.id &&
                                        voucher.code && (
                                          <AuthButton
                                            variant="outlined"
                                            size="medium"
                                            color="primary"
                                            onClick={() =>
                                              this.copyToClipboard(
                                                'voucher.code'
                                              )
                                            }
                                          >
                                            Gutscheincode: {voucher.code}
                                          </AuthButton>
                                        )}
                                    </div>

                                    <p
                                      className="voucherDescription"
                                      dangerouslySetInnerHTML={{
                                        __html: voucher.description || '',
                                      }}
                                      onClick={(e: any) =>
                                        Helper.clickHandler(
                                          e,
                                          this.props.history
                                        )
                                      }
                                    ></p>

                                    <div className="ShopDetail-voucher-info">
                                      <Headline type="h4" color="secondary">
                                        {parseInt(
                                          `${voucher.mimimumOrderValue}`,
                                          10
                                        ) > 0 && (
                                          <span>
                                            Mindestbestellwert:{' '}
                                            {Helper.formatPrice(100, 'EUR')}
                                          </span>
                                        )}
                                      </Headline>

                                      <Headline
                                        type="h4"
                                        color="secondary"
                                        style={{
                                          textAlign: 'right',
                                        }}
                                      >
                                        Gültig bis:{' '}
                                        {Helper.formatDate(voucher.validTo)}
                                      </Headline>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </Box>
                          </Grid>
                        )}

                      {this.state.shop.important && (
                        <Grid item xs={12}>
                          <Box>
                            <Headline type="h2" size="small">
                              Bitte beachten Sie bei {this.state.shop.name}
                            </Headline>

                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.shop.important || '',
                              }}
                              onClick={(e: any) =>
                                Helper.clickHandler(e, this.props.history)
                              }
                            ></p>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item md={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box style={{ position: 'relative' }}>
                          {Auth.isLoggedIn() && (
                            <span
                              style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                display: 'block',
                              }}
                              className="favouriteStar"
                            >
                              <button onClick={this.handleToggleFavorite}>
                                <span>
                                  <FontAwesomeIcon
                                    className="color-secondary"
                                    icon={
                                      this.state.isFavorite
                                        ? faStarSolid
                                        : faStar
                                    }
                                  />
                                </span>
                              </button>
                            </span>
                          )}

                          <img src={this.state.shop.logo} width="100%" alt="" />

                          <ul className="shortLinks">
                            {Auth.isLoggedIn() && (
                              <li>
                                <button onClick={this.handleToggleFavorite}>
                                  <span
                                    style={{
                                      marginRight: '10px',
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={
                                        this.state.isFavorite
                                          ? faStarSolid
                                          : faStar
                                      }
                                    />
                                  </span>
                                  <span>
                                    {this.state.isFavorite
                                      ? 'Favorit löschen'
                                      : 'Als Favorit markieren'}
                                  </span>
                                </button>
                              </li>
                            )}

                            {hasCashbackRates && (
                              <li>
                                <a href="#cashback">
                                  <span
                                    style={{
                                      marginRight: '10px',
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faPercent} />
                                  </span>

                                  <span>Cashbackübersicht</span>
                                </a>
                              </li>
                            )}

                            {this.state.shop.vouchers &&
                              this.state.shop.vouchers.length > 0 && (
                                <li>
                                  <a href="#gutscheine">
                                    <span
                                      style={{
                                        marginRight: '10px',
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faCut} />
                                    </span>

                                    <span>Gutscheine</span>
                                  </a>
                                </li>
                              )}
                          </ul>
                        </Box>

                        {!this.state.shop.complainable && (
                          <Box
                            style={{
                              padding: '0 10px 15px 10px',
                            }}
                          >
                            <Notification
                              type="warning"
                              title="Keine Nachbuchungsanfrage möglich"
                            />
                          </Box>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Box>
                          <Headline
                            type="h3"
                            style={{
                              marginTop: 0,
                              fontSize: 19,
                            }}
                          >
                            Bitte beachten Sie
                          </Headline>

                          <p>
                            Allgemeine Informationen Bitte achten Sie darauf,
                            dass Gutscheine meist nicht mit dem angegebenen
                            Cashback kombiniert werden können. Dies gilt auch
                            für käuflich erworbene Gutscheine im Shop selbst
                            oder von Groupon o.ä. Die Verwendung von Gutscheinen
                            führt in den meisten Fällen zur Ablehnung des Bonus.
                          </p>

                          <p>
                            Alle prozentualen Rabatte beziehen sich auf den
                            Bruttoeinkaufswert. Versandkosten werden nicht mit
                            in die Bonusberechnung einbezogen, der
                            Bruttoeinkaufswert ist damit im Normalfall etwas
                            geringer als der Rechnungsbetrag.
                          </p>

                          <p>
                            Nachbuchungsanfragen müssen spätestens 3 Monate nach
                            Buchung versendet werden. Anfragen, die später
                            eingehen werden nicht berücksichtigt.
                          </p>
                        </Box>
                      </Grid>

                      {this.state.similarShops &&
                        this.state.similarShops.length > 0 && (
                          <Grid item md={12}>
                            <Grid container spacing={2}>
                              {this.state.similarShops.map(
                                (shop: any, key: number) => (
                                  <Grid item xs={12} key={key}>
                                    <ShopTile
                                      image={shop.logo}
                                      buttonLabel="Zum Shop"
                                      morePath={`/shop/${shop.id}`}
                                      buttonLink={shop.link}
                                      isFavorite={shop.isFavorite}
                                      buttonStyle={{
                                        borderWidth: 2,
                                        marginTop: 5,
                                      }}
                                      history={this.props.history}
                                      shopId={shop.id}
                                      iban={
                                        this.state.user?.userInfo?.sepa_iban
                                      }
                                    >
                                      {shop.maximumCashback && (
                                        <Fragment>
                                          Bis zu{' '}
                                          <strong>
                                            {Helper.formatNumber(
                                              shop.maximumCashback,
                                              2
                                            )}{' '}
                                            {shop.maximumCashbackType}
                                          </strong>{' '}
                                          bei {shop.name}
                                        </Fragment>
                                      )}
                                    </ShopTile>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Fragment>
          )}

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.showCopyFeedback}
            autoHideDuration={5000}
            onClose={() => this.setState({ showCopyFeedback: false })}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Gutscheincode wurde kopiert</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ showCopyFeedback: false })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>,
            ]}
          />
        </InnerLayout>
      </Layout>
    );
  }
}
