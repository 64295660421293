import * as React from 'react';

import { AppLoading } from '../components/AppLoading';
import Auth from '../services/auth';
import StateManager from '../services/manager/StateManager';
import Headline from '../components/Headline/Headline';
import { Button } from '@material-ui/core';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import { Loading } from '../components/Loading';
import Box from '../components/Box/Box';

interface State {
  loading: boolean;
}

interface Props {}

export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    window.setTimeout(() => {
      Auth.logout();
      this.setState({
        loading: false,
      });
    }, 500);
  }

  render() {
    return (
      <Layout>
        {this.state.loading && (
          <div>
            <InnerLayout>
              <Loading />
            </InnerLayout>
          </div>
        )}

        {!this.state.loading && (
          <Box>
            <InnerLayout>
              <Box className="noHorizontalPaddingOnMobile">
                <Headline type="h2">Erfolgreich abgemeldet</Headline>
                <a href="https://financepilot-pe.mlp.de/banking-private/entry?linie=mlp-financepilot&prt=report">
                  <Button color="primary" variant="contained">
                    Erneut Anmelden
                  </Button>
                </a>
              </Box>
            </InnerLayout>
          </Box>
        )}
      </Layout>
    );
  }
}
