import './InputWithButton.scss';

import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputBase, Paper } from '@material-ui/core';
import React from 'react';

interface Props {
  placeholder?: string;
  value?: string;
  onClick?: any;
  onChange?: any;
  onKeyDown?: any;
  buttonLabel?: string;
  buttonType?: 'iconButton' | 'button';
}

export default ({
  placeholder,
  buttonLabel,
  onClick,
  onChange,
  onKeyDown,
  value,
  buttonType,
}: Props) => (
  <Paper elevation={0} className="InputWithButton">
    <InputBase
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    {buttonType === 'iconButton' && (
      <div className="icon-button-container">
        <FontAwesomeIcon
          size="lg"
          icon={faSearch}
          className="color-secondary"
        />
      </div>
    )}
    {buttonType === 'button' && (
      <Button variant="contained" color={'primary'}>
        {buttonLabel || 'Jetzt eintragen'}
      </Button>
    )}
  </Paper>
);
