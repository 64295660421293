import './DashboardBox.scss';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Headline from '../Headline/Headline';

interface Props {
  children?: any;
  icon?: any;
  title?: string;
  link?: string;
  placedOnSidebar?: boolean;
}

export default function DasboardBox(props: Props) {
  return (
    <a href={props.link}>
      <div
        className="DashboardBox"
        style={{
          height: props.placedOnSidebar ? 'auto' : '100%',
        }}
      >
        {props.title && (
          <div className="DashboardBoxTitle">
            <Headline type="h2" color="white">
              {props.title}
            </Headline>

            {props.icon && (
              <div className="DashboardBoxIcon">
                <FontAwesomeIcon icon={props.icon as IconName} size="lg" />
              </div>
            )}
          </div>
        )}

        {props.children && (
          <div className="DashboardBoxChildren">{props.children}</div>
        )}
      </div>
    </a>
  );
}
