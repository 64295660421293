import './Home.scss';

import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import KpiTile from '../../components/KpiTile/KpiTile';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import { CmsFile } from '../../interfaces/cmsFile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { Alert } from '../../services/alert';
import Config from '../../services/config';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import NewsletterManager from '../../services/manager/NewsletterManager';
import StateManager from '../../services/manager/StateManager';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import UserManager from '../../services/manager/UserManager';

interface Props {
  history: any;
}

interface State {
  cashbackShops: Shop[];
  specials: Special[];
  loading: boolean;
  redirectToSearch: boolean;
  query: string;
  newsletterEmail: string;
  content?: ContentElements;
  fashionId: string;
  titelBilder?: any;
  link1Bild?: CmsFile;
  link2Bild?: CmsFile;
  user?: User;
}

export class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      specials: [],
      loading: true,
      redirectToSearch: false,
      query: '',
      newsletterEmail: '',
      fashionId: '',
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeNewsletterEmail = this.handleChangeNewsletterEmail.bind(
      this
    );
    this.handleSubscribeNewsletter = this.handleSubscribeNewsletter.bind(this);
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const loggedIn = await Auth.checkLogin();
    if (loggedIn) {
      const user = await UserManager.me();
      this.setState({ user });
    }

    const fashionId = Config.getFashionCategoryId();

    const [cashbackShops, content] = await Promise.all([
      CashbackManager.findNewestShops(1, 4),
      CmsManager.getPageContentsCleaned('startseite'),
    ]);

    if (typeof cashbackShops?.items === 'object')
      cashbackShops.items = Object.values(cashbackShops.items);

    if (!cashbackShops?.items) return;

    const specials = await CashbackManager.findSpecials();

    let titelBilder: any = [];

    if (content.titelbilderFiles && content.titelbilderFiles.length > 0) {
      (content.titelbilderFiles as CmsFile[]).forEach((file: CmsFile) => {
        titelBilder = [...titelBilder, { src: file.url }];
      });
    }

    const link1Bild = content.link1Files?.[0] as CmsFile;
    const link2Bild = content.link2Files?.[0] as CmsFile;

    this.setState({
      cashbackShops: cashbackShops.items,
      specials: specials.slice(0, 2),
      loading: false,
      content,
      fashionId,
      titelBilder,
      link1Bild,
      link2Bild,
    });
  }

  handleSearch() {
    this.setState({
      redirectToSearch: true,
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleChangeNewsletterEmail(e: any) {
    this.setState({
      newsletterEmail: e.target.value,
    });
  }

  async handleSubscribeNewsletter() {
    if (this.state.newsletterEmail.trim() === '') return;

    const response = await NewsletterManager.subscribe(
      this.state.newsletterEmail
    );

    if (response) {
      Alert.success(
        'Newsletter',
        'Vielen Dank für Ihre Anmeldung zum Newsletter!'
      );
    } else {
      Alert.error(
        'Newsletter',
        'Bitte überprüfen Sie die angegebene E-Mail Adresse.'
      );
    }

    this.setState({
      newsletterEmail: '',
    });
  }

  render() {
    if (this.state.redirectToSearch)
      return <Redirect to={`/shops-und-produkte?q=${this.state.query}`} />;

    return (
      <Layout>
        <div className="Home">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Home - MLP Cashback</title>
            <meta
              name="title"
              content="MLP Vorteilswelt: Ihr Bonus in Ihrem Girokonto der MLP"
            />
            <meta
              name="description"
              content="Nutzen Sie exklusive Vorteile und Services mit der neuen MLP Vorteilswelt."
            />
          </Helmet>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <Fragment>
              {this.state.titelBilder && this.state.titelBilder.length > 0 && (
                <ImageSlider images={this.state.titelBilder} />
              )}

              {this.state.specials.length > 0 && (
                <Box className="noVerticalPaddingOnMobile">
                  <InnerLayout>
                    <Box className="noHorizontalPaddingOnMobile">
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="cashbackSpecials">
                          {this.state.specials.length > 0 && (
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <div
                                  style={{
                                    textAlign: 'center',
                                  }}
                                >
                                  <Headline
                                    type="h1"
                                    style={{ fontWeight: 'normal' }}
                                  >
                                    Cashback-Highlights
                                  </Headline>
                                </div>
                              </Grid>

                              {this.state.specials.map((special, key) => (
                                <Grid item xs={12} sm={6} key={key}>
                                  <SpecialTile
                                    image={special.image}
                                    title={special.title}
                                    subTitle={special.subTitle}
                                    info={
                                      special.end
                                        ? `Nur bis zum ${Helper.formatDate(
                                            special.end
                                          )}`
                                        : undefined
                                    }
                                    shopId={special.id}
                                    history={this.props.history}
                                    buttonLabel="Zum Shop"
                                    buttonLink={special.link}
                                    priceActual={special.priceActual}
                                    priceBefore={special.priceBefore}
                                    specialText={special.text}
                                  ></SpecialTile>
                                </Grid>
                              ))}
                              <Grid
                                xs={12}
                                item
                                style={{
                                  textAlign: 'center',
                                  padding: '1em',
                                  paddingTop: '2em',
                                }}
                              >
                                <Button
                                  href="/angebote"
                                  variant="contained"
                                  color="primary"
                                >
                                  Alle Highlights anzeigen
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </InnerLayout>
                </Box>
              )}
              {this.state.cashbackShops.length > 0 && (
                <Box color="blue">
                  <InnerLayout>
                    <Box
                      color="blue"
                      style={{ paddingBottom: 50 }}
                      className="noHorizontalPaddingOnMobile noVerticalPaddingOnMobile"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Headline
                            type="h1"
                            style={{ fontWeight: 'normal' }}
                            classNames="centered"
                          >
                            Cashback-Shops
                          </Headline>
                        </Grid>

                        {this.state.cashbackShops.map((shop: Shop, key) => (
                          <Grid item xs={12} sm={6} md={3} key={key}>
                            <ShopTile
                              image={shop.logo}
                              buttonLabel="Zum Shop"
                              morePath={`/shop/${shop.id}`}
                              buttonLink={shop.link}
                              isFavorite={shop.isFavorite}
                              onFavoriteChange={this.handleFavoriteChange(shop)}
                              history={this.props.history}
                              shopId={shop.id}
                              iban={this.state?.user?.userInfo?.sepa_iban}
                            >
                              {shop.maximumCashback && (
                                <Fragment>
                                  Bis zu{' '}
                                  <span>
                                    {Helper.formatNumber(
                                      shop.maximumCashback,
                                      2
                                    )}{' '}
                                    {shop.maximumCashbackType}
                                  </span>{' '}
                                  bei {shop.name}
                                </Fragment>
                              )}
                            </ShopTile>
                          </Grid>
                        ))}

                        <Grid
                          xs={12}
                          item
                          style={{
                            textAlign: 'center',
                            padding: '1em',
                            paddingTop: '2em',
                          }}
                        >
                          <Button
                            href="/cashback/shops"
                            variant="contained"
                            color="primary"
                          >
                            Alle Cashback-Shops anzeigen
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </InnerLayout>
                </Box>
              )}
              {this.state.content &&
                this.state.content.link1Title &&
                this.state.content.link1Links.length > 0 &&
                this.state.link1Bild &&
                this.state.content.link2Title &&
                this.state.content.link2Links.length > 0 &&
                this.state.link2Bild && (
                  <InnerLayout style={{ marginTop: 40 }}>
                    <Box>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <KpiTile
                            title={this.state.content.link1Title}
                            link={this.state.content.link1Links[0].link}
                            image={this.state.link1Bild.url}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <KpiTile
                            title={this.state.content.link2Title}
                            link={this.state.content.link2Links[0].link}
                            image={this.state.link2Bild.url}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </InnerLayout>
                )}
            </Fragment>
          )}
        </div>
      </Layout>
    );
  }
}
