import React from 'react';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';

interface Props {
  isOpen: boolean;
  toggleSidebarDrawer: () => void;
}

export default function ContactSidebar(props: Props) {
  return (
    <div className={'sidebarDrawer' + (props.isOpen ? ' active' : '')}>
      <div className="sidebarDrawerIconsWrapper">
        <div
          className="sidebarDrawerIcons"
          onClick={() => props.toggleSidebarDrawer()}
        >
          <FontAwesomeIcon icon={faPhone} />
          <FontAwesomeIcon icon={faEnvelope} />
        </div>
      </div>

      <div className="sidebarDrawerContent">
        <h3 className="m-0">Kontakt</h3>
        <div className="tel icon-box">
          <div className="row">
            <Grid container>
              <Grid item xs={2}>
                <div className="col-2 icon-box-icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="col-9 icon-content">
                  <span className="icon-content-small">Service-Telefon</span>
                  <span className="icon-content-big">
                    <a href="tel:+495217000277" className="link--tel">
                      0521 7000 277
                    </a>
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="mail icon-box">
          <div className="row">
            <Grid container>
              <Grid item xs={2}>
                <div className="col-2 icon-box-icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
              </Grid>
              <Grid item xs={9}>
                <div className="col-9 icon-content-small">
                  <span className="icon-content-small">E-Mail</span>
                  <span className="icon-content-big">
                    <a
                      href="mailto:info@mlp-cashback.de"
                      className="text-black"
                    >
                      info@mlp-cashback.de
                    </a>
                  </span>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="row mt-3">
          <Grid container>
            <Grid item xs={6}>
              <div className="col-6">
                <p className="mb-0">Mo - Fr:</p>
                <p>Sa:</p>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="col-6">
                <p className="mb-0">8 - 20 Uhr</p>
                <p>8 - 14 Uhr</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
