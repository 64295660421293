import './Pagination.scss';

import { faAngleLeft, faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import React from 'react';
import { Fragment } from 'react';

interface Props {
  currentPage: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const maxPrevNext = 2;
  const pagesBefore = [];
  const pagesAfter = [];
  let prevPage = props.currentPage;
  prevPage--;
  let nextPage = props.currentPage;
  nextPage++;
  const lastPage = props.numberOfPages;

  for (let i = prevPage; i > 0 && pagesBefore.length < maxPrevNext; i--) {
    pagesBefore.push(i);
  }

  for (
    let i = nextPage;
    i <= props.numberOfPages && pagesAfter.length < maxPrevNext;
    i++
  ) {
    pagesAfter.push(i);
  }

  return (
    <div className="Pagination">
      <Hidden only={'xs'}>
        {props.currentPage > 1 && (
          <Fragment>
            <span
              style={{ width: 'auto' }}
              onClick={() => props.onPageChange(prevPage)}
            >
              <FontAwesomeIcon icon={faAngleLeft} /> ZURÜCK
            </span>
          </Fragment>
        )}
      </Hidden>

      {pagesBefore.indexOf(1) === -1 && props.currentPage > maxPrevNext + 1 && (
        <span onClick={() => props.onPageChange(1)}>{1}</span>
      )}

      {(pagesBefore.includes(1) || pagesBefore.includes(2)) &&
        props.currentPage > 4 && (
          <span onClick={() => props.onPageChange(2)}>2</span>
        )}

      {!pagesBefore.includes(1) &&
        !pagesBefore.includes(2) &&
        props.currentPage > 4 && <span className="noHover">...</span>}

      {pagesBefore.reverse().map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}

      <span className="active">{props.currentPage}</span>

      {pagesAfter.map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}

      {props.numberOfPages > 1 &&
        pagesAfter.indexOf(lastPage - 1) === -1 &&
        props.currentPage !== lastPage - 1 &&
        props.currentPage !== lastPage && <span className="noHover">...</span>}

      {pagesAfter.indexOf(lastPage) === -1 &&
        props.currentPage < props.numberOfPages && (
          <span onClick={() => props.onPageChange(lastPage)}>{lastPage}</span>
        )}

      <Hidden only={'xs'}>
        {props.currentPage < props.numberOfPages && (
          <Fragment>
            <span
              style={{ width: 'auto' }}
              onClick={() => props.onPageChange(nextPage)}
            >
              WEITER <FontAwesomeIcon icon={faAngleRight} />
            </span>
          </Fragment>
        )}
      </Hidden>
    </div>
  );
}
