import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Helmet } from 'react-helmet';

import Box from '../components/Box/Box';
import Headline from '../components/Headline/Headline';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import { Loading } from '../components/Loading';
import UserManager from '../services/manager/UserManager';

interface Props {}

interface State {
  loading: boolean;
  email: string;
  sent: boolean;
}

export default class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      sent: false,
    };

    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeEmail(e: any) {
    this.setState({
      email: e.target.value,
    });
  }

  async handleSubmit() {
    if (this.state.email.trim() === '') return;

    this.setState({
      loading: true,
    });

    await UserManager.resetPassword(this.state.email);

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Passwort zurücksetzen - MLP Cashback</title>
        </Helmet>
        {this.state.sent && (
          <InnerLayout>
            <Box>
              <Headline type="h1">Passwort zurücksetzen</Headline>

              <Headline type="h2" color="secondary" styling="thin">
                Sie erhalten in den nächsten Minuten eine E-Mail mit einem Link
                zum Zurücksetzen Ihres Passworts.
              </Headline>
            </Box>
          </InnerLayout>
        )}

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && !this.state.sent && (
          <div>
            <InnerLayout>
              <Box>
                <Headline type="h1">Passwort zurücksetzen</Headline>
              </Box>
            </InnerLayout>

            <Box color="blue">
              <InnerLayout>
                <Box color="transparent">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          label="E-Mail Adresse"
                          variant="filled"
                          autoComplete={'email'}
                          value={this.state.email}
                          onChange={this.handleChangeEmail}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        lg={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleSubmit}
                        >
                          Passwort zurücksetzen
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </InnerLayout>
            </Box>
          </div>
        )}
      </Layout>
    );
  }
}
