import './Tickets.scss';

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import IntroSection from '../../components/IntroSection/IntroSection';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { CmsFile } from '../../interfaces/cmsFile';
import { ContentElements } from '../../interfaces/ContentElements';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';
import CmsManager from './../../services/manager/CmsManager';

interface Props {
  history: any;
}

interface State {
  content?: ContentElements;
  user?: User;
  loading: boolean;
  titelBilder?: any;
}

export default class TravelBooking extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      const script = document.createElement('script');
      script.src = 'https://www.eventim.de/obj/nucleus/partner/iframe.js';
      script.async = true;
      document.head.appendChild(script);
    }

    const content = await CmsManager.getPageContentsCleaned('tickets');

    let titelBilder: any = [];

    content.titelbilderFiles &&
      (content.titelbilderFiles as CmsFile[]).forEach((file: CmsFile) => {
        titelBilder = [...titelBilder, { src: file.url }];
      });

    this.setState({
      content,
      titelBilder,
      loading: false,
    });
  }

  hideFrameLoading() {
    const el = document.getElementById('iframe-loading');
    if (el) el.style.display = 'none';

    (window as any).eventim.iframe();
  }

  render() {
    const fkn = Auth.getFKN();

    return (
      <Layout>
        <div className="Tickets">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Tickets - MLP Cashback</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <Fragment>
              {this.state.titelBilder && this.state.titelBilder.length > 0 && (
                <ImageSlider images={this.state.titelBilder} />
              )}
              <InnerLayout>
                <Box className="noHorizontalPaddingOnMobile">
                  <IntroSection
                    headline={this.state.content?.pageTitle}
                    text={this.state.content?.pageDescription}
                  />
                </Box>

                <Box color="blue">
                  {Auth.isLoggedIn() && (
                    <div>
                      <div id="iframe-loading">
                        <Loading />
                      </div>

                      <iframe
                        src={`https://www.eventim.de/noapp/?affiliate=mlp&referer_info=${fkn}`}
                        title="Eventim"
                        width="100%"
                        height="1350"
                        style={{
                          border: 0,
                        }}
                        onLoad={this.hideFrameLoading}
                      ></iframe>
                    </div>
                  )}

                  {!Auth.isLoggedIn() && (
                    <div style={{ position: 'relative' }}>
                      <img
                        src="/assets/images/tickets-blurred.jpg"
                        alt=""
                        style={{
                          width: '100%',
                        }}
                      />

                      <div className="loginOverlay">
                        <div className="loginRequired">
                          <Headline type="h2" color="white">
                            Bitte einloggen
                          </Headline>

                          <p style={{ color: '#ffffff' }}>
                            Bitte loggen Sie sich ein, damit Sie sich die besten
                            Tickteangebote sichern können. Anschließend haben
                            Sie die Möglichkeit das gewünschte Event auszuwählen
                            und zu buchen.
                          </p>

                          <AuthButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            style={{
                              alignSelf: 'flex-end',
                              marginTop: '2em',
                            }}
                          >
                            Login
                          </AuthButton>
                        </div>
                      </div>
                    </div>
                  )}
                </Box>
              </InnerLayout>
            </Fragment>
          )}
        </div>
      </Layout>
    );
  }
}
