import './TravelBookingTeaser.scss';

import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

import Headline from '../Headline/Headline';

interface Props {
  children?: any;
  image?: string;
  title?: string;
  info?: string;
  ctaText?: string;
}

export default function TravelBookingTeaser(props: Props) {
  return (
    <div className="TravelBookingTeaser">
      <div className="content-wrapper">
        <div className="wrapper">
          <Headline type="h3" children={props.title} />
          <p>{props.info}</p>
        </div>

        <Link
          to="/reise/buchung"
          color="secondary"
          style={{ textTransform: 'uppercase', alignSelf: 'flex-end' }}
        >
          {props.ctaText || 'Jetzt Reise Planen'}
          <FontAwesomeIcon icon={faArrowRight} style={{ marginLeft: 10 }} />
        </Link>
      </div>

      <div className="image-wrapper">
        {props.image && <img src={props.image} alt="Ihre Traumreise" />}
      </div>
    </div>
  );
}
