import './Travel.scss';

import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import IntroSection from '../../components/IntroSection/IntroSection';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import ShortlinkBox from '../../components/ShortlinkBox/ShortlinkBox';
import TravelBookingTeaser from '../../components/TravelBookingTeaser/TravelBookingTeaser';
import { CmsFile } from '../../interfaces/cmsFile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Config from '../../services/config';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';
import UserManager from '../../services/manager/UserManager';
import CmsManager from './../../services/manager/CmsManager';

interface Props {
  history: any;
}

interface State {
  travelHighlights: Shop[];
  loading: boolean;
  content?: ContentElements;
  user?: User;
  sliderContent: string;
  travelId: string;
  personalTravelImage?: CmsFile;
  titelBilder?: any;
}

export default class Travel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      travelHighlights: [],
      loading: true,
      sliderContent: '',
      travelId: '',
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();
    const travelId = Config.getTravelCategoryId();

    const [travelHighlights, content] = await Promise.all([
      CashbackManager.findShopByCategoryId(travelId, 1, 4),
      CmsManager.getPageContentsCleaned('reisen'),
    ]);

    let titelBilder: any = [];

    if (content.titelbilderFiles && content.titelbilderFiles.length > 0) {
      (content.titelbilderFiles as CmsFile[]).forEach((file: CmsFile) => {
        titelBilder = [...titelBilder, { src: file.url }];
      });
    }

    const personalTravelImage = content
      ?.yourpersonaltravelFiles?.[0] as CmsFile;

    this.setState({
      travelHighlights: travelHighlights?.items,
      travelId,
      content,
      loading: false,
      personalTravelImage,
      titelBilder,
    });

    let sliderContent = '';

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });

      if (
        user.userInfo._aditoBasicContractType ===
        'd039df5d-e363-4bdd-a940-7bee28a688a2'
      ) {
        // SGirokonto
        sliderContent = content.reisesliders as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        'b703a1ff-7a24-40bc-809e-80eed136fa3d'
      ) {
        // MGirokonto
        sliderContent = content.reisesliderm as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        'bfcd240e-371d-4539-a8c9-11e740de802f'
      ) {
        // LGirokonto
        sliderContent = content.reisesliderl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        '3d7ff9bd-860c-4346-9254-c13a980622e0'
      ) {
        // XLGirokonto
        sliderContent = content.reisesliderxl as string;
      } else if (
        user.userInfo._aditoBasicContractType ===
        '5a225adc-df14-4ff5-81a2-13dfa634e0ee'
      ) {
        // XLMGirokonto
        sliderContent = content.reisesliderxl as string;
      }
    } else {
      sliderContent = content.reiseslidernichteingeloggt as string;
    }

    this.setState({ sliderContent });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.travelHighlights;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        travelHighlights: shops,
      });
    };
  }

  render() {
    return (
      <Layout>
        <div className="Travel">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Reise - MLP Cashback</title>
          </Helmet>
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <div>
              {this.state.titelBilder && this.state.titelBilder.length > 0 && (
                <ImageSlider images={this.state.titelBilder} />
              )}

              <InnerLayout>
                <Box
                  style={{
                    paddingTop: 50,
                    paddingBottom: 50,
                  }}
                >
                  <IntroSection
                    headline={this.state.content?.pageTitle}
                    text={this.state.content?.pageDescription}
                    fkn={
                      Auth.isLoggedIn()
                        ? this.state.user?.userInfo._aditoBasicContractId
                        : ''
                    }
                    contactInformation={{
                      phoneNumber: this.state.content?.phonenumberSubtitle,
                      serviceHours: [
                        {
                          days: 'Montag bis Freitag',
                          from: this.state.content?.servicemonfrifromSubtitle,
                          to: this.state.content?.servicemonfritoSubtitle,
                        },
                        {
                          days: 'Samstag',
                          from: this.state.content?.servicesatfromSubtitle,
                          to: this.state.content?.servicesattoSubtitle,
                        },
                      ],
                    }}
                  />
                </Box>
              </InnerLayout>

              <Box color="ultraLightBackground">
                <InnerLayout>
                  <Box
                    color="transparent"
                    className="noHorizontalPaddingOnMobile"
                  >
                    <Grid container>
                      <TravelBookingTeaser
                        title={
                          this.state.content?.yourpersonaltravelTitle as string
                        }
                        info={
                          this.state.content
                            ?.yourpersonaltravelSubtitle as string
                        }
                        ctaText={
                          this.state.content
                            ?.yourpersonaltravelctaTitle as string
                        }
                        image={this.state.personalTravelImage?.url as string}
                      />
                    </Grid>
                  </Box>
                </InnerLayout>
              </Box>

              <Box
                color="blue"
                style={{ paddingTop: 0 }}
                className="noVerticalPaddingOnMobile"
              >
                <InnerLayout>
                  <Box
                    color="transparent"
                    className="noHorizontalPaddingOnMobile"
                  >
                    <Headline
                      type="h3"
                      styling="thin"
                      classNames="align-center"
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: this.state.content
                            ?.cashbackshopsTitle as string,
                        }}
                      />
                    </Headline>

                    <Grid container spacing={2}>
                      {this.state.travelHighlights?.map((shop: Shop, key) => (
                        <Grid item xs={12} sm={6} md={3} key={key}>
                          <ShopTile
                            image={shop.logo}
                            buttonLabel="Zum Shop"
                            morePath={`/shop/${shop.id}`}
                            buttonLink={shop.link}
                            isFavorite={shop.isFavorite}
                            onFavoriteChange={this.handleFavoriteChange(shop)}
                            buttonStyle={{ borderWidth: 2, marginTop: 5 }}
                            history={this.props.history}
                            shopId={shop.id}
                            iban={this.state.user?.userInfo?.sepa_iban}
                          >
                            {shop.maximumCashback && (
                              <Fragment>
                                Bis zu{' '}
                                <span>
                                  {Helper.formatNumber(shop.maximumCashback, 2)}{' '}
                                  {shop.maximumCashbackType}
                                </span>{' '}
                                bei {shop.name}
                              </Fragment>
                            )}
                          </ShopTile>
                        </Grid>
                      ))}

                      <Grid item xs={12}>
                        <ShortlinkBox
                          link={{
                            pathname: '/cashback/shops',
                            state: {
                              filterState: {
                                page: 1,
                                filter: {},
                                categoryFilter: this.state.travelId,
                                orderBy: 'popularity',
                                direction: 'desc',
                              },
                            },
                          }}
                          buttonText={
                            this.state.content?.cashbackshopsSubtitle as string
                          }
                          style={{ paddingTop: 40, paddingBottom: 0 }}
                        ></ShortlinkBox>
                      </Grid>
                    </Grid>
                  </Box>
                </InnerLayout>
              </Box>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
