import './ProductDetail.scss';

import { faPercent } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden } from '@material-ui/core';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { AuthButton } from '../../components/AuthButton';
import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import InputWithButton from '../../components/InputWithButton/InputWithButton';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import Product from '../../interfaces/product';
import ProductOffer from '../../interfaces/productOffer';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {
  match: any;
  history: any;
}

interface State {
  product?: Product;
  offers: ProductOffer[];
  loading: boolean;
  currentFilter: any;
  shopLogo: string;
  query?: string;
  filteredOffers: any;
}

export default class ProductDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      offers: [],
      currentFilter: {},
      shopLogo: '',
      filteredOffers: [],
    };
  }

  async componentDidMount() {
    const currentFilter = await StateManager.getState('pap');
    const product = await CashbackManager.findProduct(
      this.props.match.params.id
    );

    const shop = await CashbackManager.findShop(product.shop.id);

    let offers: ProductOffer[] = [];
    if (product.unifiedProductId) {
      offers = await CashbackManager.findProductOffers(
        product.unifiedProductId
      );
    }

    if (offers.length === 0) {
      offers.push(product);
    }

    const filteredOffers = offers ? [...offers] : [];

    this.setState({
      product,
      offers,
      loading: false,
      currentFilter,
      shopLogo: shop.logo,
      filteredOffers,
    });
  }

  filterOffers(query: string) {
    let filtered: ProductOffer[] | undefined;

    if (query.length > 0) {
      query = query.toLowerCase().trim();

      filtered = this.state.offers.filter(
        (rate: any): ProductOffer =>
          rate.brand.toLowerCase().includes(query) ||
          rate.price.toString().includes(query) ||
          rate.shop.name.toLowerCase().includes(query)
      );
    } else {
      filtered = this.state.offers ? [...this.state.offers] : [];
    }

    this.setState({
      filteredOffers: filtered,
    });
  }

  render() {
    const renderTableSearch = () => (
      <div className="table-search">
        <InputWithButton
          value={this.state.query}
          onChange={(e: any) => this.filterOffers(e.target.value)}
          buttonType="iconButton"
        />
      </div>
    );
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Produktansicht - MLP Cashback</title>
        </Helmet>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.product && (
          <InnerLayout className="ProductDetail" alternativeBackground>
            <Box color="transparent" style={{ paddingBottom: 0 }}>
              <Backlink
                label="Zurück zur Übersicht"
                state={this.state.currentFilter}
                to="/cashback/produkte"
              />
            </Box>

            <Box color="transparent" style={{ paddingTop: 0 }}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item md={9}>
                  <Box
                    style={{
                      height: '100%',
                    }}
                  >
                    <Headline type="h1" size="small">
                      {this.state.product.name}
                    </Headline>

                    <p>{this.state.product?.description}</p>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <AuthButton
                        color="primary"
                        variant="outlined"
                        size="medium"
                        href={this.state.product?.link}
                        target="_blank"
                        style={{ marginTop: '15px' }}
                        onClick={(e: any) =>
                          Helper.handleClickoutHint(
                            {
                              type: 'shop',
                              url:
                                this.state &&
                                this.state.product &&
                                this.state.product.link,
                              shopName:
                                this.state &&
                                this.state.product &&
                                this.state.product.shop &&
                                this.state.product.shop.name,
                              shopId:
                                this.state &&
                                this.state.product &&
                                this.state.product.shop &&
                                this.state.product.shop.id,
                            },
                            e,
                            this.props.history
                          )
                        }
                      >
                        zum Shop
                      </AuthButton>

                      <div className="shopWrapper">
                        <img
                          src={this.state.shopLogo}
                          className="shopLogo"
                          alt={this.state.product?.shop.name}
                        />

                        <div>
                          <div
                            style={{
                              marginBottom: 5,
                            }}
                          >
                            ab{' '}
                            <strong
                              className="color-primary"
                              style={{
                                fontSize: 24,
                              }}
                            >
                              {Helper.formatPrice(
                                this.state.product?.price / 100,
                                this.state.product.currency
                              )}
                            </strong>
                          </div>
                          gefunden auf{' '}
                          <a
                            href={
                              this.state &&
                              this.state.product &&
                              this.state.product.link
                                ? this.state.product.link
                                : '#'
                            }
                            onClick={(e: any) =>
                              Helper.handleClickoutHint(
                                {
                                  type: 'shop',
                                  url:
                                    this.state &&
                                    this.state.product &&
                                    this.state.product.link,
                                  shopName:
                                    this.state &&
                                    this.state.product &&
                                    this.state.product.shop &&
                                    this.state.product.shop.name,
                                  shopId:
                                    this.state &&
                                    this.state.product &&
                                    this.state.product.shop &&
                                    this.state.product.shop.id,
                                },
                                e,
                                this.props.history
                              )
                            }
                          >
                            {this.state.product?.shop.name}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Grid>

                <Grid item md={3} style={{ width: '100%' }}>
                  <Box
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <img
                      src={this.state.product?.imageUrl}
                      width="100%"
                      alt=""
                    />
                    <ul className="shortLinks">
                      <li>
                        <a href="#angebote">
                          <span
                            style={{
                              marginRight: '10px',
                            }}
                          >
                            <FontAwesomeIcon icon={faPercent} />
                          </span>

                          <span>Angebotsübersicht</span>
                        </a>
                      </li>
                    </ul>
                  </Box>
                </Grid>
              </Grid>

              <Box
                style={{
                  marginTop: '1em',
                }}
              >
                <Headline type="h2" id="angebote">
                  Angebote
                </Headline>

                <ResponsiveTable
                  renderSearchField={renderTableSearch}
                  options={{
                    pagination: true,
                  }}
                  columns={[
                    {
                      name: 'brand',
                      label: 'Marke',
                      textAlign: 'left',
                      notCollapseOnMobile: true,
                    },
                    {
                      name: 'price',
                      label: 'Preis',
                      textAlign: 'left',
                      customBodyRender: (value: any) =>
                        isNaN(value) ? value : Helper.formatPrice(value || 0),
                    },
                    {
                      name: 'shipping',
                      label: 'Versandkosten',
                      textAlign: 'left',
                      customBodyRender: (value: any) =>
                        value === 0
                          ? 'Kostenlos'
                          : Helper.formatPrice(value || 0),
                    },
                    {
                      name: 'total',
                      label: 'Gesamtpreis',
                      notCollapseOnMobile: true,
                      textAlign: 'left',
                      customBodyRender: (value: any) =>
                        isNaN(value) ? value : Helper.formatPrice(value || 0),
                    },
                    {
                      name: 'cashback',
                      label: 'Cashback',
                      textAlign: 'left',
                    },
                    {
                      name: 'shop',
                      label: 'Angebot bei',
                      textAlign: 'left',
                    },
                    {
                      name: 'btn',
                      label: ' ',
                      notCollapseOnMobile: true,
                    },
                  ]}
                  data={this.state.filteredOffers.map((offer: ProductOffer) => {
                    return {
                      brand: offer.brand,
                      price: offer.price / 100,
                      shipping: offer.shippingCosts
                        ? offer.shippingCosts / 100
                        : 0,
                      total: offer.shippingCosts
                        ? (offer.price + offer.shippingCosts) / 100
                        : offer.price / 100,
                      cashback: `bis zu ${Helper.formatPrice(
                        offer.shop.maximumCashback || 0,
                        offer.shop.maximumCashbackType || ''
                      )}`,
                      shop: offer.shop.name,
                      btn: (
                        <div>
                          <Hidden smDown>
                            <AuthButton
                              color="primary"
                              variant="text"
                              href={offer.link}
                              target="_blank"
                              onClick={(e: any) =>
                                Helper.handleClickoutHint(
                                  {
                                    type: 'shop',
                                    url:
                                      this.state &&
                                      this.state.product &&
                                      this.state.product.link,
                                    shopName:
                                      this.state &&
                                      this.state.product &&
                                      this.state.product.shop &&
                                      this.state.product.shop.name,
                                    shopId:
                                      this.state &&
                                      this.state.product &&
                                      this.state.product.shop &&
                                      this.state.product.shop.id,
                                  },
                                  e,
                                  this.props.history
                                )
                              }
                            >
                              ZUM ANGEBOT
                            </AuthButton>
                          </Hidden>
                          <Hidden mdUp>
                            {Auth.isLoggedIn() && (
                              <a
                                href={offer.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                ZUM ANGEBOT
                              </a>
                            )}
                          </Hidden>
                        </div>
                      ),
                    };
                  })}
                />
              </Box>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
