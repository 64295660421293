import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { CashoutStatus } from '../interfaces/CashoutStatus';
export default class Helper {
  static formatNumber(value: number, decimals = 0) {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  static formatDate(date: any) {
    if (!date) return '';
    if (typeof date !== 'object') {
      if (date.indexOf('T') === -1) {
        date = date.replace(/-/g, '/');
      }
      date = new Date(date);
    }
    const prefix = (c: number) => (c < 10 ? `0${c}` : c);
    return `${prefix(date.getDate())}.${prefix(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  static formatTimestamp(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString('de-DE');
  }

  static dateToString(date?: Date | null) {
    if (!date) return undefined;
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  static formatPrice(value: number, currency?: string, negative?: boolean) {
    const price = value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!currency || ['EUR', 'eur', 'euro', 'Euro', '€'].indexOf(currency) > -1)
      currency = 'EUR';

    return `${negative ? '-' : ''}${price} ${currency}`;
  }

  static canViewPage(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      'd039df5d-e363-4bdd-a940-7bee28a688a2': ['Tickets'],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '65fb9e6f-b41a-457f-a177-d9f61dfc4a10': [],
    };

    if (!blacklist[accountType]) return false;

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static canViewRoute(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      'd039df5d-e363-4bdd-a940-7bee28a688a2': ['/tickets'],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '65fb9e6f-b41a-457f-a177-d9f61dfc4a10': [],
    };

    if (!blacklist[accountType]) return false;

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static hasSecurityService(service: string, accountType: string) {
    const blacklist: any = {
      // SGirokonto
      'd039df5d-e363-4bdd-a940-7bee28a688a2': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
        'turoffnungsservice',
      ],
      // MGirokonto
      'b703a1ff-7a24-40bc-809e-80eed136fa3d': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
        'turoffnungsservice',
      ],
      // LGirokonto
      'bfcd240e-371d-4539-a8c9-11e740de802f': [
        'kartenunddokumentenschutz',
        'notfallbargeld',
      ],
      // XLGirokonto
      '3d7ff9bd-860c-4346-9254-c13a980622e0': [],
      // XLMGirokonto
      '65fb9e6f-b41a-457f-a177-d9f61dfc4a10': [],
    };

    return blacklist[accountType].indexOf(service) === -1;
  }

  static clickHandler(event: any, history: any) {
    let el = event.target;

    while (el && el !== event.currentTarget && el.tagName !== 'A') {
      el = el.parentNode;
    }

    if (el && el.tagName === 'A') {
      if (el.target && el.target === '_blank') {
        event.preventDefault();

        this.handleClickoutHint({ url: el.href }, event, history);
      } else {
        return;
      }
    }
  }

  static handleClickoutHint(data: any, e: any, history: any) {
    e.preventDefault();

    history.push({
      pathname: '/clickout',
      state: data,
    });
  }

  static getLabelForCashoutStatus(status: CashoutStatus) {
    const CASHOUT_STATUS_MAP: { [key: string]: string } = {
      requested: 'Angefordert',
      cancelled: 'Storniert',
      confirmed: 'Bestätigt',
      processing: 'In Arbeit',
      'missing Adito Data': 'Offen',
      'undefined adito data': 'Offen',
    };

    return CASHOUT_STATUS_MAP[status] || 'Status unbekannt';
  }

  static handleIbanPopUp(info: any, e: any) {
    if (info.iban) {
      this.handleClickoutHint(
        {
          type: 'shop',
          url: info.buttonLink,
          shopName: info.shopName,
          shopId: info.shopId,
        },
        e,
        info.history
      );
    } else {
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        icon: 'info',
        title: 'IBAN-hinterlegen',
        html:
          'Um sich das Cashback erfolgreich sammeln und auszahlen lassen zu können, müssen Sie Ihre IBAN im "Mein Konto" hinterlegen',
        confirmButtonText: 'IBAN hinterlegen',
        cancelButtonText: 'Jetzt nicht',
        showCancelButton: true,
        focusConfirm: false,
      }).then(async result => {
        sessionStorage.setItem('showIbanModal', 'false');

        if (result.isConfirmed) {
          window.location.href = '/meine-daten/meine-auszahlungseinstellungen';
        }
      });
    }
  }
}
