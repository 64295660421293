import './Navigation.scss';

import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Hidden, Tooltip } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import UserManager from '../../services/manager/UserManager';

interface Props {
  items: any[];
  toggleDrawer: any;
}
interface State {
  user?: User;
}

export default class Navigation extends React.Component<Props, State> {
  async componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  render() {
    return (
      <div className="navigation">
        <Container maxWidth="lg">
          <div className="nav-wrapper">
            <div className="nav-item logo">
              <NavLink to={'/'}>
                <img src="/assets/images/logo.svg" alt="" />
              </NavLink>
            </div>

            <Hidden mdDown>
              <div className="nav-item nav-item-list">
                <nav>
                  {this.props.items.map((item, index) => {
                    if (Auth.isLoggedIn() && item.title === 'Registrierung')
                      return null;
                    return (
                      <div key={'nav-' + index}>
                        {!Auth.isLoggedIn() && (
                          <NavLink activeClassName="active" to={item.link}>
                            {item.title}
                          </NavLink>
                        )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === true && (
                            <NavLink activeClassName="active" to={item.link}>
                              {item.title}
                            </NavLink>
                          )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === false && (
                            <Tooltip
                              arrow
                              title={
                                <span>
                                  Leider ist diese Funktion noch nicht für Sie
                                  freigeschaltet.
                                  <br />
                                  Bitte wenden Sie sich an Ihre/n Sparkassen
                                  Berater/in.
                                </span>
                              }
                            >
                              <span className="disabledNavigationItem">
                                {item.title}
                              </span>
                            </Tooltip>
                          )}
                      </div>
                    );
                  })}

                  {!Auth.isLoggedIn() && (
                    <a href="https://financepilot-pe.mlp.de/banking-private/entry?linie=mlp-financepilot&prt=report">
                      <Button color="primary" variant="contained">
                        Login
                      </Button>
                    </a>
                  )}

                  {!!Auth.isLoggedIn() && (
                    <div>
                      <NavLink
                        to={'/mein-konto'}
                        className="mein-konto"
                        activeClassName=""
                      >
                        <Button color="primary" variant="contained">
                          Mein Konto
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </nav>
              </div>
            </Hidden>

            <Hidden lgUp>
              <div
                style={{
                  marginLeft: 15,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="nav-item">
                  <div className="burger">
                    <Button onClick={() => this.props.toggleDrawer()}>
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                  </div>
                </div>

                <div className="nav-item">
                  {!Auth.isLoggedIn() && (
                    <a href="https://financepilot-pe.mlp.de/banking-private/entry?linie=mlp-financepilot&prt=report">
                      <Button color="primary" variant="contained">
                        LOGIN
                      </Button>
                    </a>
                  )}

                  {!!Auth.isLoggedIn() && (
                    <div>
                      <NavLink to={'/mein-konto'}>
                        <Button color="primary" variant="contained">
                          MEIN KONTO
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </Hidden>
          </div>
        </Container>
      </div>
    );
  }
}
