import './Registration.scss';

import DateFnsUtils from '@date-io/date-fns';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DeLocale from 'date-fns/locale/de';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import UserLegitimation from '../../interfaces/userLegitimation';
import UserRegistration from '../../interfaces/userRegistration';
import { Alert } from '../../services/alert';
import Helper from '../../services/helper';
import UserManager from '../../services/manager/UserManager';

interface Props {
  match: any;
}

interface State {
  loading: boolean;
  awatingSubmission: boolean;
  redirect: boolean;
  accountId: string;
  salutation: string;
  firstName: string;
  lastName: string;
  email: string;
  dayOfBirth?: Date | null;
  password: string;
  password2: string;
  newsletter: boolean;
  privacy: boolean;
  terms: boolean;
  datatransfer: boolean;
  errors: object;
  step: number;
  message: string;
  userId: string;
  communication: boolean;
}

export default class Registration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: false,
      loading: false,
      awatingSubmission: false,
      accountId: '',
      salutation: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      newsletter: false,
      privacy: false,
      terms: false,
      datatransfer: false,
      dayOfBirth: null,
      errors: {},
      step: 1,
      message: '',
      userId: '',
      communication: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleStep1Submit = this.handleStep1Submit.bind(this);
    this.handleStep2Submit = this.handleStep2Submit.bind(this);
    this.validateStep1 = this.validateStep1.bind(this);
    this.validateStep2 = this.validateStep2.bind(this);
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]:
          field === 'dayOfBirth'
            ? e
            : field === 'privacy' ||
              field === 'newsletter' ||
              field === 'communication' ||
              field === 'datatransfer'
            ? e.target.checked
            : e.target.value,
      } as any);
    };
  }

  isPasswordSecure(password: string) {
    return !!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/);
  }

  validateStep1() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = [
      'dayOfBirth',
      'accountId',
      'salutation',
      'firstName',
      'lastName',
    ];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === 'string' && state[field].trim() === '')
      )
        errors[field] = 'Dieses Feld muss ausgefüllt werden';
    });

    if (
      state.dayOfBirth &&
      Helper.formatDate(state.dayOfBirth).indexOf('NaN') > -1
    ) {
      errors.dayOfBirth = 'Bitte geben Sie ein korrektes Geburtsdatum ein';
    }

    this.setState({
      errors,
    });

    return JSON.stringify(errors) === '{}';
  }

  validateStep2() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = [
      'email',
      'password',
      'password2',
      'privacy',
      'datatransfer',
    ];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === 'string' && state[field].trim() === '')
      )
        errors[field] = 'Dieses Feld muss ausgefüllt werden';
    });

    if (state.password !== state.password2)
      errors.password2 = 'Die Passwörter stimmen nicht überein';

    if (!this.isPasswordSecure(state.password))
      errors.password =
        'Bitte geben Sie mindestens acht Zeichen, eine Zahl, einen Großbuchstaben und einen Kleinbuchstaben ein.';

    if (state.email.indexOf('@') === -1 || state.email.indexOf('.') === -1) {
      errors.email = 'Bitte geben Sie eine gültige E-Mail Adresse an';
    }

    this.setState({
      errors,
    });

    return JSON.stringify(errors) === '{}';
  }

  async handleStep1Submit() {
    if (!this.validateStep1()) return;

    this.setState({
      awatingSubmission: true,
    });

    const data: UserLegitimation = {
      salutation: this.state.salutation,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dayOfBirth: Helper.dateToString(this.state.dayOfBirth),
      username: this.state.accountId,
      password: Math.random().toString(16),
      verification: false,
    };

    try {
      const legitimation = await UserManager.legitimate(data);

      this.setState({
        step: 2,
        userId: legitimation.id,
        awatingSubmission: false,
      });
    } catch (e) {
      if (e.statusCode === 404) {
        this.setState({
          message:
            'Leider konnte die von Ihnen angegebene IBAN nicht gefunden werden. Bitte überprüfen Sie Ihre Angaben oder wenden Sie sich an den Support.',
        });
      } else if (e.statusCode === 406) {
        this.setState({
          message:
            'Leider passt der von Ihnen angegeben Vor- und/oder Nachname nicht zur von Ihnen angegebenen IBAN. Bitte überprüfen Sie Ihre Angaben oder wenden Sie sich an den Support.',
        });
      } else if (e.message.indexOf('IAM: Mail already exists') > -1) {
        this.setState({
          message:
            'Es liegt bereits ein Zugang mit den von Ihnen angegebenen Daten vor. Bitte <a href="/login">loggen Sie sich ein</a> oder verwenden Sie die <a href="/passwort-vergessen">"Passwort vergessen"</a>-Funktion.',
        });
      } else {
        this.setState({
          message:
            'Etwas hat nicht funktioniert. Bitte versuchen Sie es später noch einmal.',
        });
      }

      this.setState({
        awatingSubmission: false,
      });

      return;
    }
  }

  // 200 alles cool
  // 404 Wenn IBAN weder in Adito noch in NewTrisko
  // 406 Hat IBAN gefunden, aber Vor-/Nachname passt nicht
  // 409 Mail schon vergeben
  // 500 nochmal versuchen

  async handleStep2Submit() {
    if (!this.validateStep2()) return;

    this.setState({
      loading: true,
    });

    const data: UserRegistration = {
      username: this.state.email,
      email: this.state.email,
      password: this.state.password,
      newsletter: this.state.newsletter,
      communicationAccepted: this.state.communication,
    };

    try {
      await UserManager.register(this.state.userId, data);
    } catch (e) {
      if (e.toString().indexOf('409') !== -1) {
        Alert.error(
          'Registrierung',
          'Das hat leider nicht geklappt. Falls Sie sich bereits registriert haben, loggen Sie sich bitte ein.'
        );
      } else {
        Alert.error(
          'Registrierung',
          'Leider ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.'
        );
      }

      this.setState({
        loading: false,
      });

      return;
    }

    Alert.success(
      'Vielen Dank für Ihre Registrierung!',
      'Nach der Bestätigung Ihrer E-Mail Adresse können Sie sich hier mit Ihren Zugangsdaten anmelden.'
    );

    this.setState({
      redirect: true,
      loading: false,
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/" />;

    if (this.state.loading) {
      return (
        <Layout>
          <div className="Registration">
            <InnerLayout>
              <Loading />
            </InnerLayout>
          </div>
        </Layout>
      );
    }

    return (
      <Layout>
        <div className="Registration">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Registrierung - MLP Cashback</title>
          </Helmet>
          <InnerLayout>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box>
                  <Headline type="h1">
                    Als MLP Vorteilswelt Kunde registrieren
                  </Headline>

                  <div style={{ width: '80%' }}>
                    <p>
                      Hier können Sie sich in der MLP Vorteilswelt registrieren.
                      Nach der Registrierung können Sie direkt mit dem Cashback
                      sammeln loslegen!
                    </p>
                  </div>

                  {this.state.awatingSubmission && <Loading />}
                </Box>
              </Grid>
            </Grid>
          </InnerLayout>

          {this.state.step === 1 && !this.state.awatingSubmission && (
            <Box color="blue">
              <InnerLayout>
                <Box color="transparent">
                  <Box>
                    <Grid container spacing={2}>
                      {this.state.message !== '' && (
                        <Grid item xs={12}>
                          <div style={{}}>
                            <p
                              style={{
                                color: '#ff0100',
                                border: '1px solid #ff0100',
                                padding: '10px 20px',
                              }}
                              dangerouslySetInnerHTML={{
                                __html: this.state.message,
                              }}
                            ></p>
                          </div>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <TextField
                          label="IBAN"
                          variant="filled"
                          value={this.state.accountId}
                          onChange={this.handleChange('accountId')}
                          error={errors.accountId !== undefined}
                          helperText={errors.accountId ? errors.accountId : ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl variant="filled">
                          <InputLabel id="anrede-label">Anrede</InputLabel>

                          <Select
                            labelId="anrede-label"
                            id="anrede"
                            value={this.state.salutation}
                            onChange={this.handleChange('salutation')}
                            error={errors.salutation !== undefined}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value={'Herr'}>Herr</MenuItem>
                            <MenuItem value={'Frau'}>Frau</MenuItem>
                            <MenuItem value={'Divers'}>Divers</MenuItem>
                          </Select>

                          {errors.salutation && (
                            <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error">
                              {errors.salutation}
                            </p>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Vorname"
                          variant="filled"
                          autoComplete={'given-name'}
                          value={this.state.firstName}
                          onChange={this.handleChange('firstName')}
                          error={errors.firstName !== undefined}
                          helperText={errors.firstName ? errors.firstName : ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Nachname"
                          variant="filled"
                          autoComplete={'family-name'}
                          value={this.state.lastName}
                          onChange={this.handleChange('lastName')}
                          error={errors.lastName !== undefined}
                          helperText={errors.lastName ? errors.lastName : ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={DeLocale}
                        >
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            label="Geburtsdatum"
                            value={this.state.dayOfBirth}
                            onChange={this.handleChange('dayOfBirth')}
                            style={{ margin: 0 }}
                            inputVariant={'filled'}
                            autoComplete={'bday'}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            disableFuture
                            openTo="year"
                            error={errors.dayOfBirth !== undefined}
                            helperText={
                              errors.dayOfBirth ? errors.dayOfBirth : ''
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleStep1Submit}
                        >
                          Weiter zum nächsten Schritt
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            style={{
                              marginLeft: 10,
                            }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </InnerLayout>
            </Box>
          )}

          {this.state.step === 2 && !this.state.awatingSubmission && (
            <Box color="blue">
              <InnerLayout>
                <Box color="transparent">
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="E-Mail-Adresse"
                          variant="filled"
                          autoComplete={'email'}
                          value={this.state.email}
                          onChange={this.handleChange('email')}
                          error={errors.email !== undefined}
                          helperText={errors.email ? errors.email : ''}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="Passwort"
                          type="password"
                          variant="filled"
                          value={this.state.password}
                          onChange={this.handleChange('password')}
                          error={errors.password !== undefined}
                          helperText={errors.password ? errors.password : ''}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <TextField
                          label="Passwort wiederholen"
                          type="password"
                          variant="filled"
                          value={this.state.password2}
                          onChange={this.handleChange('password2')}
                          error={errors.password2 !== undefined}
                          helperText={errors.password2 ? errors.password2 : ''}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid item lg={8}>
                          <FormControl error={errors.privacy !== undefined}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.privacy}
                                  onChange={this.handleChange('privacy')}
                                  value="true"
                                  color="primary"
                                  style={
                                    errors.privacy
                                      ? {
                                          borderWidth: 1,
                                          borderColor: 'red',
                                          borderStyle: 'solid',
                                        }
                                      : {}
                                  }
                                />
                              }
                              label={
                                <p>
                                  Ich habe die{' '}
                                  <Link to="datenschutz" target="_blank">
                                    Datenschutzinformationen
                                  </Link>{' '}
                                  und{' '}
                                  <Link to="agb" target="_blank">
                                    AGB
                                  </Link>{' '}
                                  gelesen und akzeptiere diese.
                                </p>
                              }
                            />
                            {errors.privacy && (
                              <FormHelperText>{errors.privacy}</FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item lg={8}>
                          <FormControl error={errors.newsletter !== undefined}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.newsletter}
                                  onChange={this.handleChange('newsletter')}
                                  value="true"
                                  color="primary"
                                />
                              }
                              label={
                                <p>
                                  Ich möchte künftig den MLP Vorteilswelt
                                  Newsletter per E-Mail erhalten und über
                                  Aktuelle Angebote, Schnäppchen, Gutscheine und
                                  Aktionen informiert werden.
                                </p>
                              }
                            />
                            {errors.newsletter && (
                              <FormHelperText>
                                {errors.newsletter}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item lg={8}>
                          <FormControl
                            error={errors.datatransfer !== undefined}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.datatransfer}
                                  onChange={this.handleChange('datatransfer')}
                                  value="true"
                                  color="primary"
                                  style={
                                    errors.datatransfer
                                      ? {
                                          borderWidth: 1,
                                          borderColor: 'red',
                                          borderStyle: 'solid',
                                        }
                                      : {}
                                  }
                                />
                              }
                              label={
                                <p>
                                  Einwilligung in den Datenaustausch zwischen
                                  der MLP und der Mehrwerk GmbH als
                                  Voraussetzung für die Nutzung des
                                  Vorteilskontos: Mit Ihrem Wechsel in das
                                  Vorteilskonto der MLP stehen Ihnen zahlreiche
                                  Mehrwerte zur Verfügung. Um diese Mehrwerte in
                                  vollem Umfang nutzen zu können, müssen
                                  personenbezogene Daten an die Mehrwerk GmbH in
                                  Bielefeld elektronisch übermittelt werden. Die
                                  Mehrwerk GmbH, die die Mehrwerte des
                                  MLP-Vorteilskontos zur Verfügung stellt, muss
                                  entsprechend Daten an die MLP weitergeben. Wie
                                  Ihre Daten verarbeitet werden finden Sie in
                                  den{' '}
                                  <Link to="datenschutz" target="_blank">
                                    Datenschutzinformationen
                                  </Link>
                                  . Hiermit willige ich in den Austausch der
                                  erforderlichen Daten zwischen der MLP und der
                                  Mehrwerk GmbH ein. Die Einwilligung kann
                                  jederzeit widerrufen werden, wodurch mir die
                                  Mehrwerte meines Kontos nicht mehr zur
                                  Verfügung stehen. Die bis zum Widerruf
                                  erfolgte Datenverarbeitung wird durch den
                                  Widerruf nicht berührt.
                                </p>
                              }
                            />
                            {errors.datatransfer && (
                              <FormHelperText>
                                {errors.datatransfer}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item lg={8}>
                          <FormControl
                            error={errors.communication !== undefined}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.communication}
                                  onChange={this.handleChange('communication')}
                                  value="true"
                                  color="primary"
                                  style={
                                    errors.communication
                                      ? {
                                          borderWidth: 1,
                                          borderColor: 'red',
                                          borderStyle: 'solid',
                                        }
                                      : {}
                                  }
                                />
                              }
                              label={
                                <p>
                                  Ich möchte künftig über neue Angebote und
                                  Services der Mehrwerk GmbH per E-Mail,
                                  Telefon, SMS oder WhatsApp/MMS persönlich
                                  informiert und beraten werden. Mir ist
                                  bekannt, dass ich dieses Einverständnis
                                  jederzeit formlos ohne Auswirkungen auf meinen
                                  Vertrag widerrufen kann.
                                </p>
                              }
                            />
                            {errors.terms && (
                              <FormHelperText>
                                {errors.communication}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={this.handleStep2Submit}
                          >
                            Registrierung abschließen
                            <FontAwesomeIcon
                              icon={faAngleRight}
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </InnerLayout>
            </Box>
          )}
        </div>
      </Layout>
    );
  }
}
